
import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import './TotalProgress.scss';
interface IProps {
    percent: number;
    label?:string;
}

const TotalProgress = (props: IProps) => {

    return (
        <div className={'total-progress'}>
            <div>
            <div className='total-progress-container'>
               <div className='total-progress-bar' style={{width: props.percent + '%'}}></div>
           </div>
            </div>
            <div>
                {props.label && props.label + " "}{Math.floor(props.percent)}%
            </div>
           
        </div>
    )
}

export default TotalProgress;
