import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { act } from 'react-dom/test-utils';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import FigureDisplay from '../../components/FigureDisplay';
import FigureSelect from '../../components/FigureSelect';
import FigureSlot from '../../components/FigureSlot';
import InventoryWrapper from '../../components/InventoryWrapper';
import ModalButtonBar from '../../components/ModalButtonBar';
import ModalChoice from '../../components/ModalChoice';
import ModalContainer from '../../components/ModalContainer';
import ResourceBalance from '../../components/ResourceBalance';
import SlotFigureOptions from '../../components/SlotFigureOptions';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import BoardSlotUpgrade from '../../interfaces/BoardSlotUpgrade';
import BoardUpgradeConfirmation from '../../interfaces/BoardUpgradeConfirmation';
import CostItem from '../../interfaces/CostItem';
import ExpUserBoard from '../../interfaces/ExpUserBoard';
import ExpUserBoardSlot from '../../interfaces/ExpUserBoardSlot';
import FigureDetail from '../../interfaces/FigureDetail';
import RequirementItem from '../../interfaces/RequirementItem';
import Slot from '../../interfaces/Slot';
import TransferConfirmation from '../../interfaces/TransferConfirmation';
import User from '../../interfaces/User';
import './UserPage.scss';

interface IProps {
  balances:Balances;
}

const UserPage = (props:IProps) => {

  const context = React.useContext(AppContext);
    const session = context.userSession;
    const user = session?.user || {} as User;

  const userBoard = (context.userBoard ? context.userBoard[0] : {} as ExpUserBoard);
  const activeSlots = userBoard?.slots.filter((slot:ExpUserBoardSlot) => slot.unlocked) || [];
  const [selectedSlot, setSelectedSlot] = React.useState<ExpUserBoardSlot|undefined>();
  const [showBoardOptions, setShowBoardOptions] = React.useState<boolean>(false);
  const [boardOptionsFetched, setBoardOptionsFetched] = React.useState<boolean>(false);
  const [isUpgradePending, setIsUpgradePending] = React.useState<boolean>(false);
  const [selectedUpgrade, setSelectedUpgrade] = React.useState<number>();
  const [selectedRequirementTokenPosition, setSelectedRequirementTokenPosition] = React.useState<string>();
  const [selectedUpgradeTokens, setSelectedUpgradeTokens] = React.useState<FigureDetail[]>([]);
  const [boardUpgrades, setBoardUpgrades] = React.useState<BoardSlotUpgrade[]>([]);

  function prevSlot():void {
    let prevSlot;
    for (let i = 0; i < activeSlots.length; i++) {
      if (activeSlots[i].boardLocation === selectedSlot?.boardLocation) {
        if (i === 0) {
          setSelectedSlot(activeSlots[activeSlots.length - 1]);
        } else {
          setSelectedSlot(activeSlots[i - 1]);
        }
      }
    }
  }

  function nextSlot():void {
    let newSlot = selectedSlot!.boardLocation + 1;
    if (newSlot > activeSlots.length - 1) {
      newSlot = 0;
    }
    setSelectedSlot(activeSlots[newSlot]);
  }

  React.useEffect(() => {
    setSelectedSlot(undefined);
    
    if(showBoardOptions) {
      setBoardOptionsFetched(false);
      getBoardUpgrade();
    }
}, [showBoardOptions]);

function getBoardUpgrade() {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBoardUpgrade`;
    context.setShowLoader?.('Loading The Axis Options', true);
    axios({
        "method": "GET",
        "url": url,
        "headers": {
            "signedMessage": session?.signature as string,
            "originalMessage": session?.message as string,
            "address": session?.address as string
        }
    })
        .then((response) => {
            let upgrades: BoardSlotUpgrade[] = response.data;
            setBoardUpgrades(upgrades)
            setBoardOptionsFetched(true);
            context.setShowLoader?.('Loading The Axis Options', false);
        })
        .catch((error) => {
            console.log(error);
            context.setShowLoader?.('Loading The Axis Options', false);
        })
}


  function upgradeBoardSlot() {
    
    if (selectedUpgrade && !isUpgradePending) {
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/upgradeBoard`;
        let tokens:number[] = [];
        for (let i = 0; i < boardUpgrades.length; i++) {
          if (boardUpgrades[i].id === selectedUpgrade) {
            if ( boardUpgrades[i].requirements && boardUpgrades[i].requirements.length > 0) {
              for (let j = 0; j < boardUpgrades[i].requirements.length; j++) {
                if (boardUpgrades[i].requirements[j].selectedFigure) {
                  tokens.push(boardUpgrades[i].requirements[j].selectedFigure!.id);
                }
              }
            }

          }
        }

        setIsUpgradePending(true);
        context.setShowLoader?.('Redrawing The Axis', true);
        axios({
            "method": "POST",
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string,
                'content-type': 'application/json'
            },
            "data": {priceId : selectedUpgrade, tokens : tokens}
  
        })
            .then((response) => {
              let confirmation:BoardUpgradeConfirmation = response.data;
                          context.setUserBalance?.(confirmation.balance);
                          if(confirmation.userBoard) {
                            context.updateUserBoard?.(confirmation.userBoard);
                          }
                          setShowBoardOptions(false);
                          setSelectedUpgrade(undefined);
                          setBoardUpgrades([]);
                          setIsUpgradePending(false);
                          context.updateUserFigures?.();
                          //setFilteredSupply(filterSupply(supply));
             // let elementMasteryList:ElementMastery[] = response.data;
              //setElementMasteryList(elementMasteryList);
                context.setShowLoader?.('Redrawing The Axis', false);
            })
            .catch((error) => {
                console.log(error)
                setShowBoardOptions(false);
                          setSelectedUpgrade(undefined);
                          setBoardUpgrades([]);
                          setIsUpgradePending(false);
                context.setShowLoader?.('Redrawing The Axis', false);
            })
    }
  
  
  
  }

  function canAfford(item:CostItem):boolean {
    switch (item.elementId) {
      case 1:
        return item.amount <= (context.balances?.death || 0);
      case 2:
        return item.amount <= (context.balances?.life || 0);
      case 3:
        return item.amount <= (context.balances?.fire || 0);
      case 4:
        return item.amount <= (context.balances?.water || 0);
      case 5:
        return item.amount <= (context.balances?.earth || 0);
      case 6:
        return item.amount <= (context.balances?.air || 0);
      case 7:
        return item.amount <= (context.balances?.memory || 0);
      case 8:
        return item.amount <= (context.balances?.vision || 0);
      
      }
    return false;
  }

  function canAffordAll(items:CostItem[], requirements:RequirementItem[]):boolean {
    let afford = true;
    for (let i = 0; i < items.length; i++) {
      let canAffordItem = canAfford(items[i]);
      if(!canAffordItem) {
        afford = false;
      }
    }
    if(requirements) {
      for (let i = 0; i < requirements.length; i++) {
        let canAffordItem = requirements[i].valid;
        if(!canAffordItem) {
          afford = false;
        }
      }
    }
    
    return afford;
  }

  function areTokenRequirementsMet(selectedUpgrade:number) {
    let requirements = boardUpgrades.filter((upgrade:BoardSlotUpgrade) => upgrade.id === selectedUpgrade)[0].requirements;

    let requirementsMet = true;
    if(requirements) {
      for (let i = 0; i < requirements.length; i++) {
        if (requirements[i].type === 'token') {
           if (requirements[i].selectedFigure == null) {
             requirementsMet = false;
           }
           if (requirements[i].selectedFigure?.elementId !== requirements[i].elementId) {
            requirementsMet = false;
           }
        }

      }
    }
    return requirementsMet;
  }

  function upgradeBoard():void {
    if (selectedUpgrade) {
      upgradeBoardSlot();
    }
  }

  function setRequirementItemFigure(tokenId:number) {

    if (selectedRequirementTokenPosition != null && selectedUpgrade != null) {
      let selectedToken = context.userFigures?.filter((figure:FigureDetail) => figure.id === tokenId)[0];
      let updatedBoardUpgrades = [...boardUpgrades];
      updatedBoardUpgrades[Number(selectedRequirementTokenPosition.split('-')[0])].requirements[Number(selectedRequirementTokenPosition.split('-')[1])].selectedFigure = selectedToken;
      setBoardUpgrades(updatedBoardUpgrades);
      
    }
    setSelectedRequirementTokenPosition(undefined);
    // if (selectedRequirementToken != null) {
    //   let selectedToken = context.userFigures?.filter((figure:FigureDetail) => figure.id === tokenId)[0];
    //   selectedRequirementToken.selectedFigure = selectedToken;
    //   console.log(selectedRequirementToken);
    // }
    // setSelectedRequirementToken(undefined);
  }

  function removeSelectedRequirementToken(tokenKey:String) {
    if (tokenKey != null) {
      let updatedBoardUpgrades = [...boardUpgrades];
      updatedBoardUpgrades[Number(tokenKey.split('-')[0])].requirements[Number(tokenKey.split('-')[1])].selectedFigure = undefined;
      setBoardUpgrades(updatedBoardUpgrades);
    }
  }

  function getTokensToExclude(requirementItems:RequirementItem[]):number[] {
    let tokensToExclude:number[] = [];
    requirementItems.forEach((item:RequirementItem) => 
      {if (item.selectedFigure != null) {
        tokensToExclude.push(item.selectedFigure.id);
      }}
    );
    return tokensToExclude;
  }

  return (
    <div className="user-page fade-in">
      
      {context.auth &&  <><ResourceBalance balances={props.balances} /><Vial balances={props.balances} /></>}
       {context.auth && userBoard && <Circle userBoard={userBoard} selectedSlot={selectedSlot} onSlotClick={setSelectedSlot}  />}
       {!context.auth &&
       <>
         <div className='circle-connect'><img src='/circle-connect.png' /></div>
        <button className='connect-area' onClick={context.connect}></button>
        </>
      }
      {selectedSlot && userBoard && <SlotFigureOptions prevSlot={prevSlot} nextSlot={nextSlot} userBoard={userBoard} onClose={() => setSelectedSlot(undefined)} slot={selectedSlot} />}
      {context.auth && <div className='circle-options-wrapper'>
        <div className='circle-options'>
          <div></div>
          <button onClick={() => setShowBoardOptions(true)}>The Axis</button>
          <div>
            <Link className='axis-button' to={'/experiment/research'}>
            <svg width={24} height={24} viewBox="0 0 24 24">
              <path fill="currentColor" d="M7,2V4H8V18A4,4 0 0,0 12,22A4,4 0 0,0 16,18V4H17V2H7M11,16C10.4,16 10,15.6 10,15C10,14.4 10.4,14 11,14C11.6,14 12,14.4 12,15C12,15.6 11.6,16 11,16M13,12C12.4,12 12,11.6 12,11C12,10.4 12.4,10 13,10C13.6,10 14,10.4 14,11C14,11.6 13.6,12 13,12M14,7H10V4H14V7Z" />
            </svg>
            </Link>
          </div>
        </div>
      </div>}
        {showBoardOptions == true && boardOptionsFetched && ReactDOM.createPortal(
                <ModalChoice onClose={() => setShowBoardOptions(false)}>
                    <ModalContainer className='supply-minting-modal' >

                        <h2>The Axis</h2>
                        <>
                        {boardOptionsFetched && boardUpgrades.length > 0 && <>
                        <div className='upgrade-circle-details'>
                        <img src="/circle4.png" />
                        </div>
                        <div className='circle-prices item-details'>
                          <h2>Draw a {boardUpgrades[0].boardLocation + 1} Slot Circle</h2>
                          {boardUpgrades.map((upgradeOption:BoardSlotUpgrade, upgradeIndex:number) => 
                            <div className={'price-option ' + (canAffordAll(upgradeOption.costItems, upgradeOption.requirements) ? 'can-afford' : 'cant-afford')}>
                              <label className={upgradeOption.id === selectedUpgrade ? 'active' : ''}>
                              <div className='check-box'>
                              <input
                                type="radio"
                                disabled={canAffordAll(upgradeOption.costItems, upgradeOption.requirements) ? false : true}
                                value={upgradeOption.id}
                                checked={selectedUpgrade === upgradeOption.id}
                                onChange={(e) => setSelectedUpgrade(Number(e.target.value))}
                              />
                              </div>
                                
                                {upgradeOption.requirements && upgradeOption.requirements.filter((item:RequirementItem) => item.type === 'token').length > 0 &&
                                  <div className='required-tokens'>
                                    {upgradeOption.requirements && upgradeOption.requirements.map((item:RequirementItem, index:number) =>
                                      <>
                                        {item.type === 'token' &&
                                          <div className='figure-requirement'>
                                          <div onClick={() => setSelectedRequirementTokenPosition(upgradeIndex + '-' + index)}>
                                            {!item.selectedFigure && <FigureSlot elementId={item.elementId} />}
                                            {item.selectedFigure && <FigureDisplay token={item.selectedFigure} size='half' />}
                                            {item.note && <span className='note'>{item.note}</span>}
                                          </div>
                                          {item.selectedFigure && <button onClick={() => removeSelectedRequirementToken(upgradeIndex + '-' + index)}>
                                                <svg viewBox="0 0 24 24">
<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
</svg>
                                            </button>}
                                          </div>
                                        }
                                      </> 
                                    )}
                                  </div>
                                }
                                <ul>
                                {upgradeOption.requirements && upgradeOption.requirements.map((item:RequirementItem) =>
                                  <>
                                  {item.type === 'mastery-level' &&
                                    <li key={item.type + item.elementId} className={(item.valid ? '' : ' not-enough')}><span className={'element-type element-' + item.elementId}></span> {item.label}</li>
                                  }
                                  </> 
                                )}
                                {upgradeOption.costItems.map((item:CostItem) =>
                                  <li key={item.elementId} className={(canAfford(item) ? '' : ' not-enough')}><span className={'element-type element-' + item.elementId}></span> {item.amount} {item.name}</li>
                                )}
                                </ul>
                                </label>
                            </div>
                          )}
                        </div>
                    </>}</>
                        <>
                          {boardOptionsFetched && boardUpgrades.length == 0 && 
                            <div className='modal-warning'><p>You must advance your training</p></div>
                          }
                        </>
                        <ModalButtonBar approveDisabled={selectedUpgrade && areTokenRequirementsMet(selectedUpgrade) ? false : true} onApprove={upgradeBoard} approveLabel="Upgrade" onCancel={() => setShowBoardOptions(false)} />

                    </ModalContainer>
                </ModalChoice>
                , document.body)
            }
            {selectedRequirementTokenPosition && ReactDOM.createPortal(
                <FigureSelect exclude={getTokensToExclude(boardUpgrades[Number(selectedRequirementTokenPosition.split('-')[0])].requirements)} criteria={"element=" + boardUpgrades[Number(selectedRequirementTokenPosition.split('-')[0])].requirements[Number(selectedRequirementTokenPosition.split('-')[1])].elementId} onSelect={setRequirementItemFigure} onClose={() => setSelectedRequirementTokenPosition(undefined)} />
                , document.body)
            }
    </div>
  )
}

export default UserPage;