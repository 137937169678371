import axios from "axios";
import Discovery from "../interfaces/Discovery";
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_BASEPATH}`,
  headers: {
    "Content-type": "application/json",
  },
});

const findByClaimCode = async (claimCode: string) => {
  const response = await apiClient.get<Discovery>(`/discovery/claim/${claimCode}`);
  return response.data;
}

const claimDiscovery = async (claimCode : string, nameOrAddress : string) => {
  const response = await apiClient.post<Discovery>(`/discovery/claim/${claimCode}`, nameOrAddress);
  return response.data;
}

const DiscoveryService = {
    findByClaimCode,
    claimDiscovery
}
export default DiscoveryService;