import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Token from '../../../interfaces/Token';
import GalleryFigure from '../GalleryFigure';


import './SecondEpoch.scss';


interface IProps {
    epoch:number;
}

const SecondEpoch = (props: IProps) => {

  const [tokens, setTokens] = React.useState<Token[]>([]);

  React.useEffect(() => {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/support/epoch/${props.epoch}/cover-tokens`;
    
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
        setTokens(response.data);
     
      
    })
    .catch((error) => {
      console.log(error)
    })
  }, [props.epoch]);

  function getTotalMints():number {
    let sum = 0;
    for (let i = 0; i < tokens.length; i++) {
        sum += tokens[i].totalEdition;
    }
    return sum;
  }

  return (
    <div className="second-epoch">
      <div className='part-1'>
          <div className='part-content'>
            {props.epoch === 1 &&
              <div className='intro-section'>
              <div className='epoch-dates'>2021/11/25 - 2021/12/21  {getTotalMints()} Mints</div>
            <h1 className='intro'>The First Epoch: <span className='sub-title'>Catch One Gold Crow</span></h1>
            <p>The clock is set, the longest night approaches, and the Crows&nbsp;take&nbsp;flight...</p>
          </div>
            }
            {props.epoch === 2 &&
              <div className='intro-section'>
                <div className='epoch-dates'>2021/12/21 - 2022/3/20  {getTotalMints()} Mints</div>
              <h1 className='intro'>The Second Epoch: <span className='sub-title'>Catch Five Gold Crow &amp; Harvest the Elements</span></h1>
              <p>Approach the circle and use what you have learned to begin channeling the elements. <Link to="/experiment">Participants, begin your&nbsp;experiment.</Link></p>
            </div>
            }
            {props.epoch === 3 &&
              <div className='intro-section'>
              <div className='epoch-dates'>2022/3/20 - 2022/6/21  {getTotalMints()} Mints</div>
            <h1 className='intro'>The Third Epoch: <span className='sub-title'>Catch Seven Gold Crow &amp; Expand the Circle</span></h1>
            <p>Advance your training, expand the circle, and become a master of the elements...</p>
          </div>
            }
            {props.epoch === 4 &&
              <div className='intro-section'>
              <div className='epoch-dates'>2022/6/21 - 2022/9/22  {getTotalMints()} Mints</div>
            <h1 className='intro'>The Fourth Epoch: <span className='sub-title'>Catch Nine Gold Crow &amp; The End of the Beginning</span></h1>
            <p>Focus your power and find those that were lost...</p>
          </div>
            }
            {props.epoch === 5 &&
              <div className='intro-section'>
              <div className='epoch-dates'>2022/9/22 - 2022/12/21  {getTotalMints()} Mints</div>
            <h1 className='intro'>The Fifth Epoch: <span className='sub-title'>Catch Twelve Gold Crow &amp; The Dance of Death</span></h1>
            <p>Death comes for us all, just give her time...</p>
          </div>
            }
          </div>
          
        </div>
        <div className='epoch-gallery'>
              <ul>
                  {tokens.map((token) => 
                    <li key={token.figure}>
                        <GalleryFigure name={token.description} artist={token.artist} editions={token.totalEdition} imageKey={token.imageKey}  />
                    </li>
                  )}
              </ul>
        </div>
    </div>
  )
}

export default SecondEpoch;