import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import './EpochPage.scss';
import FirstEpoch from './FirstEpoch';
import SecondEpoch from './SecondEpoch';

interface IProps {
  
}

const EpochPage = (props:IProps) => {


  //const epoch1ref = React.useRef<HTMLDivElement>(null);
  //const epoch2ref = React.useRef<HTMLDivElement>(null);
  const context = React.useContext(AppContext);

  const [epoch , setEpoch] = useState<number>(5);

  return (
    <div className="epoch-page">
      <div className='tab-nav'>
        <h2>The Epochs</h2>
      <ul>
        <li>
          <a onClick={() => setEpoch(1)}>I</a>
        </li>
        <li>
          <a onClick={() => setEpoch(2)}>II</a>
        </li>
        <li>
          <a onClick={() => setEpoch(3)}>III</a>
        </li>
        <li>
          <a onClick={() => setEpoch(4)}>IV</a>
        </li>
        <li>
          <a onClick={() => setEpoch(5)}>V</a>
        </li>
        <li>
          <a onClick={() => setEpoch(6)}>VI</a>
        </li>
      </ul>
      <span style={{transform: `translate3d(${((epoch -1) * 100)}%, 0, 0)`}} className='underline'></span>
      </div>
      <div className='tab-epoch-wrapper'>
      <span className='ink'>
            <img src="/epoch/ink.png" />
          </span>
      <div className={'epoch-section '}>
      <SecondEpoch epoch={epoch} />
      </div>
      
      
      </div>
    </div>
  )
}

export default EpochPage;