import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import {Link, useParams, Outlet} from "react-router-dom";
import { createTextChangeRange } from 'typescript';
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import './ExperimentPage.scss';



interface IProps {
  
}


        


const ExperimentPage = (props:IProps) => {


  const context = React.useContext(AppContext);
  
  
  return (
    <div className="experiment-page fade-in">
      <div className='experiment-nav'><ExperimentNav /></div>
      <div className='experiment-page-wrapper'>
      <Outlet />
      </div> 
    </div>
  )
}

export default ExperimentPage;

