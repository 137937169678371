import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import FigureDisplay from '../../../FigureDisplay';
import BoardSlot from '../BoardSlot';
import './BlockBoardHistory.scss';


interface IProps {
    taskBoard: TaskBoard | undefined;

}

const BlockBoardHistory = (props: IProps) => {
    const context = useContext(AppContext);
    const session = context.userSession;
    const taskBoard = props.taskBoard;



    function getRowCount() {
        let columns = '';
        for (let i = 0; i < (taskBoard?.size || 1); i++) {
            columns = columns + "1fr ";
        }

        return columns.trim();
    }
    return (
        <div className='block-board-history'>
            <ul>

                <>
                    {taskBoard && taskBoard.slots.filter((slot: TaskBoardSlot) => slot.token).sort((a: TaskBoardSlot, b: TaskBoardSlot) => (a.lastModified < b.lastModified ? 1 : -1)).slice(0, 8).map((slot: TaskBoardSlot) =>
                        <li key={'slot-' + slot.id}>
                            <div className={'block-board-history-item ' + (context.auth && slot.token.userId === (session?.user.id || 0) ? 'is-user' : '')}>
                                
                                <div><FigureDisplay token={slot.token} size={'quarter'} /></div>
                                <div className={context.auth && slot.token.userId === session?.user.id ? 'is-user' : ''}>{slot.token.userName}</div>
                                {slot.token.id === slot.placeholderToken.id && <span className='special'>
                                    <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                    </svg>
                                </span>}
                            </div>

                        </li>
                    )}
                </>


            </ul>
        </div>
    )
}

export default BlockBoardHistory;