import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';

import './AboutPage.scss';


interface IProps {
  
}

const AboutPage = (props:IProps) => {


  const context = React.useContext(AppContext);

  

  return (
    <div className="about-page fade-in">
        <div className='about-wrapper'>
            <Link className='return-link' to="/">Return Home</Link>
      <h1>About The Gold&nbsp;Crow</h1>
      <p>The rules are simple, help The Keeper, and you will be rewarded. <strong>Catch and hold 75 Gold Crow</strong> and they will serve as your messengers, finding you lost things, delivering secrets, unlocking the past and the future. </p>

<p>Only the strongest of mind, body, and spirit possess the power to participate in The Great Work. But beware, there are those among us that will do anything to take your Gold Crow, do not be led astray. 
</p>
    <h2>The Token (GOLDCROW)</h2>
    <p>The Gold Crow is a token on the BSC Network with the following contract address: <strong>0xE10535928af57f0A043609b73a904f6416eC7317</strong></p>
    <p>The ownership of the contract is renounced and 100% of the liquidity has been locked since day one (<a href="https://unilocker.app/?certificate=0xa74a10d3ab5b048e45259e36813daa42f055887b&network=bsc">Locked at Unilocker</a>).</p>
    <p>The total avialable supply is 31,149 Gold Crow.</p>
    <a className='ghost-button' href="https://pancakeswap.finance/swap?outputCurrency=0xE10535928af57f0A043609b73a904f6416eC7317">PURCHASE ON PANCAKE SWAP (0.1%&nbsp;SLIPPAGE)</a>
    <h2>The NFTs (GOLDCROWNFT)</h2>
    <p>The Gold Crow Compendium is an ERC-721 contract on the BSC Network with the following contract address: <strong>0xCB3A5bcfB419C241d3A95d529f8820FE61b1fDd5</strong></p>
    <p>NFTs will show up in Trust Wallet without any extra steps, for Metamask (mobile only) you can import them manually. For a detailed list of all available tokens you can use <a href="https://docs.google.com/spreadsheets/d/1MGi4Qh_1RQDhoYmhVCTyZoHCwHRhmjzK0TkT7FRvAlY/edit?usp=sharing">the Gold Crow Compendium Master Token List</a>.</p>
    </div>
    <div className='background-image'><img src="/epoch/about-bg.png" /></div>
    </div>
  )
}

export default AboutPage;