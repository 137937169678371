import axios from 'axios';
import React, { useCallback } from 'react'

import { AppContext } from '../../AppContext';
import MintableList from '../../components/MintableList';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import ResearchMasteryLevel from '../../interfaces/ResearchMasteryLevel';
import ResearchMastery from '../../interfaces/ElementMastery';
import User from '../../interfaces/User';
import './MasteryPage.scss';
import ElementMastery from '../../interfaces/ElementMastery';
import ElementMasteryLevel from '../../interfaces/ElementMasteryLevel';
import MasteryBar from '../../components/MasteryBar';
import { Link, useNavigate } from 'react-router-dom';
import LongTimer from '../../components/LongTimer';
import ResourceBalance from '../../components/ResourceBalance';

interface IProps {
  
}

const MasteryPage = (props:IProps) => {

  const context = React.useContext(AppContext);
  const session = context.userSession;
  const user = session?.user || {} as User;
  
  const navigate = useNavigate();


  function viewMasteryDetail(elementId:number) {
    let elementAlias = getElementAlias(elementId);
    navigate(elementAlias);
  }

  
  const [elementMasteryList, setElementMasteryList] = React.useState<ElementMastery[]>([]);

  React.useEffect(() => {

    getMasteryList();

  }, []);

  function getMasteryList() {
    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery`;
    context.setShowLoader?.('Loading Research', true);
    axios({
        "method": "GET",
        "url": url,
        "headers": {
          "signedMessage": session?.signature as string,
          "originalMessage": session?.message as string,
          "address": session?.address as string
        }
      })
      .then((response) => {
        let elementMasteryList:ElementMastery[] = response.data;
        setElementMasteryList(elementMasteryList);
        context.setShowLoader?.('Loading Research', false);
        //context.setShowLoader?.('Loading Tasks', false);
      })
      .catch((error) => {
        console.log(error);
        context.setShowLoader?.('Loading Research', false);
        //context.setShowLoader?.('Loading Tasks', false);
      })
  }

  function researchNextLevel(masteryLevel:ElementMasteryLevel, count:number) {
    let research = {} as ResearchMasteryLevel;
    research.count = count;
    research.masteryLevelId = masteryLevel.id;
    if (masteryLevel && count > 0) {
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery/research`;
        //setSaving(true);
        context.setShowLoader?.('Beginning Research', true);
        axios({
            "method": "POST",
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string,
                'content-type': 'application/json'
            },
            "data": masteryLevel.id

        })
            .then((response) => {
              let elementMasteryList:ElementMastery[] = response.data;
              setElementMasteryList(elementMasteryList);
                context.setShowLoader?.('Beginning Research', false);
            })
            .catch((error) => {
                console.log(error)
               
                context.setShowLoader?.('Beginning Research', false);
            })
    }



}

function getElementAlias(elementId: number): string {
  switch (elementId) {
      case 1:
          return 'death';
      case 2:
          return 'life';
      case 3:
          return 'artifact';
      case 4:
          return 'humor';
      case 5:
          return 'locus';
      case 6:
          return 'craft';
      case 7:
          return 'memory';
      case 8:
          return 'vision';

  }
  return 'death';
}

function getElementName(elementId: number): string {
  switch (elementId) {
      case 1:
          return 'Death';
      case 2:
          return 'Life';
      case 3:
          return 'Artifact (Fire)';
      case 4:
          return 'Humor (Water)';
      case 5:
          return 'Locus (Earth)';
      case 6:
          return 'Craft (Air)';
      case 7:
          return 'Memory (Past)';
      case 8:
          return 'Vision (Future)';

  }
  return 'Death';
}


function isCurrentResearch(masteryLevel:ElementMasteryLevel):boolean {
  if (masteryLevel == null) {
    return false;
  } 
  var currentResearch = masteryLevel.userMasteryExp[masteryLevel.userMasteryExp.length - 1];
  if (!currentResearch || currentResearch.secondsRemaining <= 0) {
    return false;
  } else {
    return true;
  }
}

function getRemainingLevels():number {
  let totalLevels = 0;
  for(let i = 0; i < elementMasteryList.length; i++) {
    if (elementMasteryList[i].currentLevel) {
      totalLevels = totalLevels + elementMasteryList[i].currentLevel.level;
    }
    
  }
  return totalLevels;
}

  return (
    <div className="mastery-page fade-in">
    <div className='user-balance'>
      <ResourceBalance balances={context.balances} />
      </div>
        <PageWrapper>
        <PageHeader>Research</PageHeader>
        <PageNav children={undefined}></PageNav>
        <PageContent>
          <>
          {Boolean(elementMasteryList.length > 0) && <>
            <div className='total-mastery-levels'>Total Mastery Levels {getRemainingLevels()}/10</div>
            <div className='mastery-menu fade-in'>
              
                {elementMasteryList.map((elementMastery:ElementMastery) => 
                    <div className='mastery-menu-item'>
                      <div className='mastery-select'  onClick={() => viewMasteryDetail(elementMastery.elementId)}>
                        
                      <h2 className='mastery-title'>{getElementName(elementMastery.elementId)} {elementMastery.currentLevel && <span className='mastery-modifier'>+{elementMastery.currentLevel.modifier}%</span>}</h2>
                      
                      {isCurrentResearch(elementMastery.nextLevel) && <span className='research-pending-tag'>Research Pending</span>}
                    <MasteryBar maxLevel={elementMastery.isMaxLevel} hideDetails={true} currentLevel={elementMastery.currentLevel} onResearchComplete={getMasteryList} active={elementMastery.active} blocked={elementMastery.blocked} onResearchNextLevel={researchNextLevel} elementId={elementMastery.elementId} masteryLevel={elementMastery.nextLevel} />
                    </div>
                    <div className='view-mastery'>
                    
                      <Link to={`${getElementAlias(elementMastery.elementId)}`}>Research {getElementName(elementMastery.elementId)}</Link>
                    </div>
                    </div>
                )}
            </div>
            </>}</>
        </PageContent>
      </PageWrapper>
    </div>
  )
}

export default MasteryPage;