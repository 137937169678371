import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';

import './ModalButtonBar.scss';
interface IProps {
    onApprove?():void;
    onCancel():void;
    approveDisabled?:boolean;
    approveLabel?:string;
    cancelLabel?:string;
}

const ModalButtonBar = (props: IProps) => {

    return (
        <div className={'button-bar ' + (props.onApprove ? ''  : 'single')}>
            <span>
                <button onClick={() => props.onCancel()} className='cancel-button'>{props.cancelLabel ? props.cancelLabel : 'Cancel'}</button>
            </span>
            {props.onApprove && <span>
                <button disabled={props.approveDisabled} onClick={() => props.onApprove!()} className='approve-button'>{props.approveLabel ? props.approveLabel : 'Approve'}</button>
            </span>}
        </div>
    )
}

export default ModalButtonBar;






















