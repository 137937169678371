import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import { Link, useParams, Outlet, NavLink } from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import FigureDetail from '../../components/FigureDetail';
import FigureDisplay from '../../components/FigureDisplay';
import HistoryCard from '../../components/HistoryCard/HistoryCard';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import BlockBoard from '../../components/Quest/WorldBoard/BlockBoard';
import CompletedSet from '../../components/Quest/WorldBoard/CompletedPact';
import RankingCard from '../../components/RankingCard';
import SortArrows from '../../components/SortArrows';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import CriteriaRank from '../../interfaces/CriteriaRank';
import Page from '../../interfaces/Page';
import Task from '../../interfaces/Task';
import TaskBoard from '../../interfaces/TaskBoard';
import TaskBoardSlot from '../../interfaces/TaskBoardSlot';
import TaskContributionElement from '../../interfaces/TaskContributionElement';
import TaskHistory from '../../interfaces/TaskHistory';
import User from '../../interfaces/User';
import UserTaskRank from '../../interfaces/UserTaskRank';
import './TaskHistoryPage.scss';

interface IProps {

}

const TaskHistoryPage = (props: IProps) => {
  const defaultCriteria: CriteriaRank = {
    sortOn: 'totalRank',
    direction: 'asc',
  }

  const context = React.useContext(AppContext);
  const { taskAlias } = useParams();
  const user: User = context.user || {} as User;

  const [task, setTask] = React.useState<Task>();
  const [activeElements, setActiveElements] = React.useState<number[]>([]);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  //const [pages, setPages] = React.useState<Page[]>([]);
  const [taskHistory, setTaskHistory] = React.useState<TaskHistory[]>([]);

  const [sortPages, setSortPages] = React.useState<Page[]>([]);
  const [criteria, setCriteria] = React.useState<CriteriaRank>(defaultCriteria);

  const [sortOn, _setSortOn] = React.useState<string>(defaultCriteria.sortOn);
  const [reverse, _setReverse] = React.useState<boolean>(defaultCriteria.direction === 'desc' ? true : false);
  const [rankView, _setRankView] = React.useState<string>('totalRank');
  function setReverse(reverse: boolean) {
    _setReverse(reverse);
    //localStorage.setItem("summaryReverse", String(reverse));
  }

  function setRankView(view: string) {
    _setRankView(view);
  }

  function setSortOn(sort: string) {
    if (sortOn === sort && reverse) {
      setReverse(false);
    }
    if (sortOn === sort && !reverse) {
      _setSortOn('default');
      setReverse(false);
      //localStorage.setItem("summarySort", 'compareId');
    }
    if (sortOn !== sort) {
      _setSortOn(sort);
      setReverse(true);
      //localStorage.setItem("summarySort", sort);
    }

  }

  function getFormKey(): string {
    return 'key' + criteria.sortOn + criteria.direction;
  }
  useEffect(() => {
    let newCriteria = { ...criteria };
    newCriteria.sortOn = sortOn;
    newCriteria.direction = (reverse ? 'desc' : 'asc');
    setCriteria(newCriteria);
  }, [reverse, sortOn]);

  useEffect(() => {
    //setListSize(0);
  }, [getFormKey()]);

  React.useEffect(() => {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${taskAlias}`;
    //context.setShowLoader?.('Loading Task', true);
    axios({
      "method": "GET",
      "url": url
    })
      .then((response) => {
        let task: Task = response.data;
        if (task && task.type === 1) {
          let elements: number[] = [];
          task.contribution.elements.forEach((element: TaskContributionElement) =>
            elements.push(element.id)
          );
          setActiveElements(elements);
        }
        setTask(task);
        //context.setShowLoader?.('Loading Tasks', false);
      })
      .catch((error) => {
        console.log(error);
        //context.setShowLoader?.('Loading Tasks', false);
      })

  }, []);

  React.useEffect(() => {

    if (task && task.id && task.type === 1) {
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${task.id}/history`;
      context.setShowLoader?.('Loading History', true);
      axios({
        "method": "GET",
        "url": url
      })
        .then((response) => {
          let taskHistory: TaskHistory[] = response.data;
          //let updatedPages = pages;
          //updatedPages.push(rankPage);
          setTaskHistory(taskHistory);
          context.setShowLoader?.('Loading History', false);
        })
        .catch((error) => {
          console.log(error);
          context.setShowLoader?.('Loading History', false);
        })
    }



  }, [task?.id, getFormKey()]);


  function getElementName(elementId: number): string {
    switch (elementId) {
      case 1:
        return 'Death';
      case 2:
        return 'Life';
      case 3:
        return 'Artifact (Fire)';
      case 4:
        return 'Humor (Water)';
      case 5:
        return 'Locus (Earth)';
      case 6:
        return 'Craft (Air)';
      case 7:
        return 'Memory (Past)';
      case 8:
        return 'Vision (Future)';

    }
    return 'Death';
  }

  function getFormattedDate(date: Date) {
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + (date.getDate())).slice(-2);
    var year = date.getFullYear();
    var hour = ("0" + (date.getHours())).slice(-2);
    var min = ("0" + (date.getMinutes())).slice(-2);
    var seg = ("0" + (date.getSeconds())).slice(-2);
    return year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + seg;
  }

  function sortRankView(a: UserTaskRank, b: UserTaskRank): number {
    switch (rankView) {
      case 'totalRank':
        return a.totalRank - b.totalRank;
      case 'death':
        return b.death - a.death;
      case 'life':
        return b.life - a.life;
      case 'fire':
        return b.fire - a.fire;
      case 'water':
        return b.water - a.water;
      case 'earth':
        return b.earth - a.earth;
      case 'air':
        return b.air - a.air;
      case 'memory':
        return b.memory - a.memory;
      case 'vision':
        return b.vision - a.vision;
    }
    return 0;
  }

  return (
    <div className="task-history-page fade-in">
      {task && task.type === 1 &&
      <PageWrapper>
        
          <>
            <PageHeader>{task.name}</PageHeader>
            <PageNav>
                <ul>
                  <li><NavLink to={`../world`} end>World</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/rankings`} end>Rankings</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
                </ul>
              </PageNav>
            <div className='task-rankings-page-content'>
              <h2>History</h2>
              <div className='table-view'>
                {task &&
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Date/Time</th>
                          <th>Participant</th>
                          <th>Type</th>
                          <th>Element</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskHistory.map((taskHistory: TaskHistory) =>
                          <>

                            <tr key={taskHistory.id} className={'fade-in-fast ' + (taskHistory.userId === context?.userSession?.user?.id ? 'current-user' : '')}>
                              <td>{getFormattedDate(new Date(taskHistory.timestamp))}</td>
                              <td className='rank-username'>{taskHistory.userName}</td>
                              <td>Sacrifice</td>
                              <td>{getElementName(taskHistory.elementId)}</td>
                              <td>{taskHistory.amount}</td>
                            </tr>

                          </>
                        )}
                      </tbody>
                    </table>
                    {Boolean(taskHistory.length > 1) && <div className='end-of-list'>THE END</div>}
                  </>
                }
              </div>
              <div className='list-view'>
                {task &&
                  <>



                    <ul className='list-view-results'>
                      {taskHistory.map((taskHistory: TaskHistory) =>
                        <>

                          <li className={'fade-in-fast ' + (taskHistory.userId === context?.userSession?.user?.id ? 'current-user' : '')}>
                            <HistoryCard taskHistory={taskHistory} />
                          </li>

                        </>
                      )}
                    </ul>

                    {Boolean(taskHistory.length > 1) && <div className='end-of-list'>THE END</div>}
                  </>
                }
              </div>
            </div>
          </>
      </PageWrapper>}

      {task && task.type === 2 &&
      <PageWrapper>
        
          <>
            <PageHeader>{task.name}</PageHeader>
            <PageNav>
                <ul>
                  <li><NavLink to={`../world`} end>World</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
                </ul>
              </PageNav>
            <div className='task-rankings-page-content'>
              <h2>History</h2>
              
              <div className='completed-sets'>
                    <ul>
                        
                        {task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status >= 2).map((taskBoard:TaskBoard) => 
                            <li key={'taskboard-' + taskBoard.id}>
                                <CompletedSet taskBoard={taskBoard} />
                            </li>
                        )}
                        </ul>
                    </div>
            </div>
          </>
      </PageWrapper>}

      {task && task.type === 3 &&
      <PageWrapper>
        
          <>
            <PageHeader>{task.name}</PageHeader>
            <PageNav>
                <ul>
                  <li><NavLink to={`../world`} end>World</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/rewards`} end>Rewards</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
                </ul>
              </PageNav>
            <div className='task-rankings-page-content'>
              <h2>History</h2>
              
              <div className='completed-sets evidence'>
                    <ul>
                        
                        {task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status >= 2).map((taskBoard:TaskBoard, index:number) => 
                            <li key={'taskboard-' + taskBoard.id}>
                              <h3>Board {index + 1}</h3>
                                <BlockBoard taskBoard={taskBoard} selectTaskSlot={undefined} claimToken={undefined} />
                                {Boolean(taskBoard.slots.filter((slot:TaskBoardSlot) => slot.token && slot.token.userId == user.id).length > 0) && <div className='user-tokens'>
                                  <h3>Your Board {index + 1} Figures</h3>
                                <ul>
                                {taskBoard.slots.filter((slot:TaskBoardSlot) => slot.token && slot.token.userId == user.id).map((slot:TaskBoardSlot) => 
                                  <li key={'slot-' + slot.id}>
                                  <div className={'block-board-history-item '}>
                                      
                                      <div><FigureDisplay token={slot.token} size={'quarter'} /></div>
                                      {slot.token.id === slot.placeholderToken.id && <span className='special'>
                                          <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                              <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                          </svg>
                                      </span>}
                                      <FigureDetail figure={slot.token && !slot.token.mask ? slot.token : slot.placeholderToken}/>
                                  </div>
      
                              </li>
                                 )}
                                </ul>
                                </div>}
                            </li>
                        )}
                        </ul>
                    </div>
            </div>
          </>
      </PageWrapper>}

      {task && task.type === 4 &&
      <PageWrapper>
        
          <>
            <PageHeader>{task.name}</PageHeader>
            <PageNav>
                <ul>
                  <li><NavLink to={`../world`} end>World</NavLink></li>
                  <li><NavLink to={`../world/${task.alias}/history`} end>History</NavLink></li>
                </ul>
              </PageNav>
            <div className='task-rankings-page-content'>
              <h2>History</h2>
              
              <div className='discovery-log'>
                    
                    </div>
            </div>
          </>
      </PageWrapper>}
    </div>
  )
}

export default TaskHistoryPage;