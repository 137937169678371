import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { AppContext } from '../../AppContext';
import IFigureDetail from '../../interfaces/FigureDetail';
import RewardToken from '../../interfaces/RewardToken';
import Token from '../../interfaces/Token';
import Figure from '../Figure';
import './SlotFigureOptions.scss';
import VisibilitySensor from 'react-visibility-sensor';
import { useInView } from 'react-intersection-observer';
import FigureDetail from '../FigureDetail';
import FigureDisplay from '../FigureDisplay';
import Slot from '../../interfaces/Slot';
import ExpUserBoard from '../../interfaces/ExpUserBoard';
import ExpUserBoardSlot from '../../interfaces/ExpUserBoardSlot';

interface IProps {
    //criteria:string;
    slot: ExpUserBoardSlot;
    //slots:number;
    //onSelect(tokenId:number):void;
    onClose(): void;
    userBoard:ExpUserBoard;
    nextSlot():void;
    prevSlot():void;
}



const SlotFigureOptions = (props: IProps) => {

    const context = React.useContext(AppContext);
    const session = context.userSession;

    // const [searchResults, setSearchResults] = React.useState<IFigureDetail[]>([]);
    const [currentView, setCurrentView] = React.useState<string>('swap');

    const activeSlots = props.userBoard.slots.filter((slot:ExpUserBoardSlot) => slot.unlocked);
    const slots = activeSlots.filter((slot) => slot.type != 'focus').length;


    const searchResults = context.userFigures?.filter(filterFigures) || [];


    function filterFigures(figure:IFigureDetail):boolean {
        if(props.slot.type.startsWith('tier')) {
            let slotTier = Number(props.slot.type.replace('tier',''));
           // console.log(slotTier + " " + figure.tier)
            if (figure.tier <= slotTier) {
                
                return true;
            } else {
                return false;
            }
        }
        if (props.slot.type === 'focus') {
            if (figure.id != props.slot.token?.id && figure.focus) {
                return true;
            }
            return false;
        } else {
            if (figure.id != props.slot.token?.id) {
                return true;
            }
            return false;
        }
        
    }

    function onSelect(figure: IFigureDetail): void {
        if (context) {
            props.onClose();
            context.placeFigureOnUserBoard?.(props.slot, figure);
        }
    }

    function onRemove(figure: IFigureDetail): void {
        if (context) {
            props.onClose();
            context.removeFigureFromUserBoard?.(figure);
        }
    }

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && props.onClose) {
                props.onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    


    return (

        <div className='slot-figure-options'>
            <div className='figure-select-close'><button onClick={props.onClose}><svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg></button></div>
            <div className='slot-content'>
                <div className='slot-figure-nav'>
                    <h1>The Axis 
                        {props.slot.type !== 'focus' && <span className='slot-number'> {props.slot.boardLocation + 1}/{slots}</span>}
                        {props.slot.type === 'focus' && <span className='slot-number'> Focus</span>}
                    </h1>
                    <div className='slot-nav'>
                        <button className='prev-button' onClick={props.prevSlot}>
                        <svg style={{width:36, height:36}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
</svg>
                        </button>
                        <button className='next-button' onClick={props.nextSlot}>
                        <svg  style={{width:36, height:36}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
</svg>
                        </button>
                    </div>
                </div>

                {currentView === 'detail' && <div className='slot-section'>
                    {props.slot.token && <div className='slot-details'>

                        <FigureDisplay token={props.slot.token} size={'half'} />
                        <FigureDetail figure={props.slot.token} />
                        
                    </div>}
                </div>}
                {currentView === 'swap' &&
                    <div className='slot-section swap-pane'>
                         {props.slot.token && <div className={'slot-details-swap element-' + props.slot.token?.elementId}>
                            <>
                                <div>
                                    <FigureDisplay token={props.slot.token} size={'half'} />
                                </div>
                                <div>
                                    <FigureDetail figure={props.slot.token} />
                                    <button className='figure-remove-button' onClick={() => onRemove(props.slot.token || {} as IFigureDetail)}>REMOVE</button>
                                </div>
                            </>
                        </div>}
                        <div className='swap-select-wrapper'>
                            <div className='figure-scroll-wrapper'>
                                <ul>
                                    {searchResults.map((figure: IFigureDetail) =>
                                        <li key={figure.id}>
                                            <div className={'figure-select-item element-' + figure.elementId} onClick={() => onSelect(figure)}>
                                                <FigureDisplay token={figure} size={'half'} />
                                                <FigureDetail figure={figure} />
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>}
            </div>
            <div className="border">
                <span className='border-top'></span>
                <span className='border-sides'></span>
                <span className='border-bottom'></span>
            </div>
        </div>

    )
}

export default SlotFigureOptions;