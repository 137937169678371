import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import ExpUserBoard from '../../interfaces/ExpUserBoard';
import ExpUserBoardSlot from '../../interfaces/ExpUserBoardSlot';
import FigureDisplay from '../FigureDisplay';


import './Focus.scss';
interface IProps {
    focusSlot:ExpUserBoardSlot;
    onSlotClick(slot:ExpUserBoardSlot):void;
}

const Focus = (props: IProps) => {

    const context = useContext(AppContext);
    const focusSlot =props.focusSlot;

    return (
        <div onClick={() => props.onSlotClick(focusSlot)} className={'focus-slot '  + (focusSlot.token ? 'in' : '')}>
           {focusSlot && <FigureDisplay token={focusSlot.token} size={'half'} />}
        </div>
    )
}

export default Focus;
