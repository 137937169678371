import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import React, { useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import Web3 from 'web3';

import { AppContext } from '../../AppContext';
import MintableToken from '../../interfaces/MintableTokens';

import paneImg from "../../image/mastery-bar-pane.png";
import capImg from "../../image/mastery-bar-cap-half.png";

import './MasteryBar.scss';
import ElementMasteryLevel from '../../interfaces/ElementMasteryLevel';
import Timer from '../Timer/Timer';
import LongTimer from '../LongTimer';
import ResearchMasteryLevel from '../../interfaces/UserMasteryExp';
import ResearchTimer from '../ResearchTimer';



interface IProps {
  blocked: boolean;
  elementId: number;
  active: boolean;
  masteryLevel: ElementMasteryLevel;
  currentLevel: ElementMasteryLevel;
  onResearchNextLevel(nextLevel: ElementMasteryLevel, count:number): void;
  onResearchComplete(): void;
  hideDetails?:boolean;
  maxLevel:boolean;
}

const MasteryBar = (props: IProps) => {
  
  const context = React.useContext(AppContext);
  const remainingResearch = (props.masteryLevel?.expRequirement || 0) - (props.masteryLevel?.userMasteryExp.length || 0);

  const [researchAmount, setResearchAmount] = React.useState<number>(0);
  
  
  
  var currentResearch: ResearchMasteryLevel | undefined = getCurrentResearch();
  if (currentResearch && currentResearch.secondsRemaining <= 0) {
    currentResearch = undefined;
  }

  const [secondsRemaining, _setSecondsRemaining] = React.useState<number | undefined>(currentResearch?.secondsRemaining || undefined);


  function getCurrentResearch():ResearchMasteryLevel | undefined {
    let research = undefined;
    if (props.masteryLevel) {
      
    for (let i = 0; i < props.masteryLevel.userMasteryExp.length; i++) {
      if (props.masteryLevel.userMasteryExp[i].status === 1) {
       research = props.masteryLevel.userMasteryExp[i];
      }
    }
    
  }
    
    return research;
  }

  React.useEffect(() => {
    _setSecondsRemaining(currentResearch?.secondsRemaining || undefined);
  }, [currentResearch?.secondsRemaining])


  function setSecondsRemaining(number: number) {
    _setSecondsRemaining(number * 1000);
  }


  function getPercentage(): number {
    if(props.masteryLevel == null) {
      return 100;
    }
    let maxLevels = props.masteryLevel.expRequirement;
    let userTraining = props.masteryLevel.userMasteryExp;
    let completedLevels = 0;
    let trainingLevels = 0;
    let partPercent = 100 / maxLevels;
    let trainingPercentAdd = 0;
    let totalSeconds = 0;
    for (let i = 0; i < userTraining.length; i++) {
      if (userTraining[i].status === 2) {
        completedLevels = completedLevels + 1;
      } else {
        trainingLevels = trainingLevels + 1;
        totalSeconds = totalSeconds + userTraining[i].totalSeconds;
      }
    }
    if( currentResearch && trainingLevels) {
      //let trainingPart = partPercent * trainingLevels;
      //console.log(((secondsRemaining || 0) / totalSeconds));
      let trainingPercent = 1 - ((secondsRemaining || 0) / totalSeconds);
      trainingPercentAdd = trainingLevels * trainingPercent;
      //console.log(trainingPercentAdd);
    }
    
//console.log(completedLevels);
    return (100 * (completedLevels + trainingPercentAdd)) / maxLevels;
  }

  function getPendingPercentage(part: number | undefined, whole: number): number {
    if (part === undefined) {
      return 0;
    }
    let percent = (100 * part) / whole;
    if (isNaN(percent)) {
      return 0;
    }
    let value = 100 - percent;
    if (value === 100 && secondsRemaining === undefined) {
      return 0;
    }
    return 100 - percent;
  }

  function getAllExp(count: number): number[] {
    let exp: number[] = [];
    for (let i = 0; i < count; i++) {
      exp.push(i);
    }
    return exp;
  }

  function onTimerEnd() {
    props.onResearchComplete();
  }


  function increaseResearchAmount() {
    let updatedResearchAmount = researchAmount + 1;
    if (updatedResearchAmount <= remainingResearch) {
      setResearchAmount(updatedResearchAmount);
    }
  }

  function decreaseResearchAmount() {
    let updatedResearchAmount = researchAmount - 1;
    if (updatedResearchAmount >= 0) {
      setResearchAmount(updatedResearchAmount);
    }
  }

  function getRomanNumeral(number: number): string {
    switch (number) {
      case 1:
        return "I";
      case 2:
        return "II";
      case 3:
        return "III";
      case 4:
        return "IV";
      case 5:
        return "V";
      case 6:
        return "VI";
      case 7:
        return "VII";
      case 8:
        return "VIII";
      case 9:
        return "IX";
      case 10:
        return "X";
      default:
        return "-";
    }
  }

  function getElementName(elementId: number): string {
    switch (elementId) {
      case 1:
        return 'Death';
      case 2:
        return 'Life';
      case 3:
        return 'Artifact (Fire)';
      case 4:
        return 'Humor (Water)';
      case 5:
        return 'Locus (Earth)';
      case 6:
        return 'Craft (Air)';
      case 7:
        return 'Memory (Past)';
      case 8:
        return 'Vision (Future)';

    }
    return 'Death';
  }
  function canAfford(amount: number): boolean {
    switch (props.elementId) {
      case 1:
        return amount <= (context.balances?.death || 0);
      case 2:
        return amount <= (context.balances?.life || 0);
      case 3:
        return amount <= (context.balances?.fire || 0);
      case 4:
        return amount <= (context.balances?.water || 0);
      case 5:
        return amount <= (context.balances?.earth || 0);
      case 6:
        return amount <= (context.balances?.air || 0);
      case 7:
        return amount <= (context.balances?.memory || 0);
      case 8:
        return amount <= (context.balances?.vision || 0);

    }
    return false;
  }


  function onResearchNextLevel(nextLevel: ElementMasteryLevel, count:number) {
    setResearchAmount(0);
    props.onResearchNextLevel(nextLevel, count);
  }

  function getResearchTime(secondsLeft: number) {
    let days = Math.floor(secondsLeft / 24 / 60 / 60);
    let hoursLeft = Math.floor((secondsLeft) - (days * 86400));
    let hours = Math.floor(hoursLeft / 3600);
    let minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
    let minutes = Math.floor(minutesLeft / 60);
    let seconds = secondsLeft % 60;
    
    //console.log(hours);
    return (
      <div className="research-timer">
        <div className="panel">
          <span>DAYS</span>
          <span>{days < 10 ? "0" + days : days}</span>
        </div>
        <div className="panel">
          <span>HOURS</span>
          <span>{hours < 10 ? "0" + hours : hours}</span>
        </div>
        <div className="panel">
          <span>MINS</span>
          <span>{minutes < 10 ? "0" + minutes : minutes}</span>
        </div>
        <div className="panel">
          <span>SECS</span>
          <span>{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
      </div>
    )
  }

  return (
    <div className='mastery-bar-wrapper fade-in'>
      <div className='title-plate'>
        <div className='current-level'>
          {props.currentLevel && <h2>{props.currentLevel.title}</h2>}
        </div>
        <div className='next-level'>
          {props.masteryLevel && <h2><span className='next-level-number'>{getRomanNumeral(props.masteryLevel.level)}</span> {props.masteryLevel.title}</h2>}
        </div>
      </div>


      <div className={'mastery-bar element-' + props.elementId + (props.blocked ? ' blocked' : '')} >
        <div className='mastery-bar-pane'>

          <img src={paneImg} />
          <span className={'sphere-glow element-' + props.elementId}></span>
          {!props.blocked && props.masteryLevel && <span className='current-level'>{getRomanNumeral(props.masteryLevel.level - 1)}</span>}
          {!props.blocked && !props.masteryLevel && <span className='current-level'>{getRomanNumeral(10)}</span>}
          {props.blocked && <span className='current-level blocked'>
          <svg width={30} height={30} viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
</svg>
            </span>}
        </div>
        <div className='mastery-bar-track'>
          <div className={'texture-overlay element-' + props.elementId} style={{ width: getPercentage() + '%' }}></div>
          <div className={'segment-vial training vial-' + props.elementId} style={{ width: getPercentage() + '%' }}></div>
          <div className='mastery-bar-segments' style={{ backgroundPosition: '0 ' + (props.elementId * 100) + 'px' }}>
            {props.masteryLevel && <span className={'glow glow-' + props.elementId} style={{ left: getPercentage() + '%' }} ></span>}
            {props.masteryLevel && getAllExp(props.masteryLevel.expRequirement).map((number: number) =>
              <div className='segment'>
                
              </div>
            )}
            
          </div>
          
        </div>
        <div className='mastery-bar-cap'>
          <img src={capImg} />
        </div>

      </div>

{!props.hideDetails &&
      <div className='mastery-action-wrapper'>
        <div className='mastery-advanced'>
          
        <div className='figure-sockets'>
            {props.masteryLevel && getAllExp(props.masteryLevel.expRequirement).map((number: number) =>
              <div className='figure-socket-wrapper'>
                <svg style={{width: '24px', height: '24px'}} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                </svg>
              <div className='figure-socket open'>
                
              </div>
              </div>
            )}
          </div>


        </div>
        <div className='mastery-action'>
          {!currentResearch && 
            <>
            <div>
            <span className='session-label'>Sessions</span>
            <div className='research-picker'>
              <button disabled={0 === researchAmount || props.blocked || props.maxLevel} className='session-button' onClick={() => decreaseResearchAmount()}>
                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,13H5V11H19V13Z" />
                </svg>
              </button>
              <div className='picker-total'>

                <span className='session-number'>{researchAmount}</span>
              </div>
              <button disabled={remainingResearch === researchAmount || props.blocked || props.maxLevel} className='session-button' onClick={() => increaseResearchAmount()}>
                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                </svg>
              </button>
            </div>
          </div>
          <div>
            <span className='session-label'>Required Time/Resources</span>
            {getResearchTime(researchAmount * 24 * 60 * 60)}
          </div>
          {props.masteryLevel && <div className='research-cost-wrapper'>
            <div className={'research-cost ' + (canAfford(researchAmount * props.masteryLevel.expCost) && researchAmount !== 0 ? '' : ' not-enough')}>
              <span className={'element-type element-' + props.elementId}></span>
              {' ' + researchAmount * props.masteryLevel.expCost + ' ' + getElementName(props.elementId)}

            </div>
          </div>}
          {!props.hideDetails && <div className='mastery-timer'>




        
        {!props.blocked && !props.maxLevel && <button className='research-session-button' disabled={!props.active || !canAfford(researchAmount * props.masteryLevel.expCost) || researchAmount === 0} onClick={() => onResearchNextLevel(props.masteryLevel, researchAmount)}>Conduct Research</button>}
      </div>}
            </>
          }
          {currentResearch &&
          <>
          <div>
            <span className='session-label'>Research Completes In</span>
            <ResearchTimer initialSeconds={Math.trunc(currentResearch.secondsRemaining / 1000)} setSecondsLeft={setSecondsRemaining} onTimerEnd={onTimerEnd} />
            </div>
          </>
          
          }
          

          
          
        </div>
      </div>}

      
      {props.hideDetails && currentResearch && <div>
        
          <>
          <div className='mini-timer'>
            <LongTimer initialSeconds={Math.trunc(currentResearch.secondsRemaining / 1000)} setSecondsLeft={setSecondsRemaining} onTimerEnd={onTimerEnd} />
            </div>
          </>
          
        </div>}
    </div>
  )
}

export default MasteryBar;