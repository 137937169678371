import axios from 'axios';
import { totalmem } from 'os';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Balances from '../../../interfaces/Balances';
import Discovery from '../../../interfaces/Discovery';
import ElementTransaction from '../../../interfaces/ElementTransaction';
import RewardToken from '../../../interfaces/RewardToken';
import Step from '../../../interfaces/Step';
import Task from '../../../interfaces/Task';
import TaskContributionElement from '../../../interfaces/TaskContributionElement';
import TransferConfirmation from '../../../interfaces/TransferConfirmation';
import UserTaskRank from '../../../interfaces/UserTaskRank';
import FigureDisplay from '../../FigureDisplay';
import ModalButtonBar from '../../ModalButtonBar';
import ModalChoice from '../../ModalChoice';
import ModalContainer from '../../ModalContainer';
import TotalProgress from '../../TotalProgress';


import './Checklist.scss';

interface IProps {
    task: Task;
    onTaskUpdate(task: Task): void;
}

const Checklist = (props: IProps) => {

    const context = useContext(AppContext);
    const session = context.userSession;
    const balances = context.balances;
    const style = { "--task-background-image": `url('https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/${props.task.backgroundImageKey}/taskbackground')` } as React.CSSProperties;

    const [steps, setSteps] = React.useState<Step[]>([]);


    React.useEffect(() => {

        
         
    
        if (context?.userSession?.user?.id) {
          let userDiscoveryUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${props.task.id}/progress/user/${context?.userSession?.user?.id}`;
          //context.setShowLoader?.('Loading Rewards', true);
          axios({
            "method": "GET",
            "url": userDiscoveryUrl
          })
            .then((response) => {
              //let rewardTokens:RewardToken[] = response.data;
              setSteps(response.data);
              //let updatedPages = pages;
              //updatedPages.push(rankPage);
              
              //context.setShowLoader?.('Loading Rewards', false);
            })
            .catch((error) => {
              console.log(error);
              //context.setShowLoader?.('Loading Rewards', false);
            });
        }
        
  }, [props.task]);


  function getPrecentComplete(steps:Step[]):number {
    let stepsComplete = 0;
    if (steps.length === 0) {
        return 0;
    }
    steps.forEach((step) => 
       { if (step.complete) {
            stepsComplete = stepsComplete + 1;
        }}
    );
    return (stepsComplete / steps.length) * 100;
  }

  const percentComplete = getPrecentComplete(steps);

    return (
        <div className={"checklist fade-in "} style={style}>
            <div className='experiment-content'>
                <div className='task-description'>
                    <div className='task-detail'>{props.task.subtitle}</div>
                    <h1>{props.task.name}</h1>
                    <div className='task-menu'>
                        <ul>
                            <li><Link to={`${props.task.alias}/history`}>History</Link></li>
                        </ul>
                    </div>
                    {percentComplete < 100 && <TotalProgress percent={percentComplete} />}
                    {percentComplete >= 100 && <span className='complete-tag'>TASK COMPLETE</span>}
                    <p>{props.task.description}</p>



                </div>
                <div className='global-vial-wrapper checklist-items'>
                    <ul>
                        {steps.map((step:Step) =>
                             <li className={(step.complete ? 'complete' : '')}>{step.description} <span className="finished">({step.totalComplete})</span></li>
                        )}
                    </ul>

                </div>

                <div className='rank-list-wrapper'>
                
                    
                </div>

                
            </div>
        </div>
    )
}

export default Checklist;