import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext';
import Balances from '../../../interfaces/Balances';

import './ElementBalance.scss';
interface IProps {
    balance:number;
    elementId:number;
    active:boolean;
    rate:number;
    modifier:number;
    showRate:boolean;
}

const ElementBalance = (props:IProps) => {
  const balance = props.balance || 0;
  


  const context = useContext(AppContext);
  const board = context.board?.slice(0,100) || [];
  
  function getFraction(n:number):number {
    return  n - Math.floor(n);
  }

  return (
    <div className={'element-balance element-' + props.elementId}>
        {props.active &&
        <>
          <div className='element-timer'>
              <div className={'element-track track-element-' + props.elementId} />
              <div className='element-progress' style={{width : getFraction(balance) * 100 + '%'}}></div>
              <span className='element-progress-glow' style={{left : getFraction(balance) * 100 + '%'}}></span>
          </div>
          <div className='element-balance-amount'>
             {Boolean(balance >= 1) ? Math.trunc(balance) : <span>-</span>}
        </div>
        {props.showRate && props.rate > 0 && 
          <div className='element-balance-rate'>
          +{Number((props.rate * 1440 * props.modifier).toFixed(1))}
        </div>
        }
        
        </>}
      </div>
  )
}

export default ElementBalance;