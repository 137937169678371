import axios from 'axios';
import React, { useContext } from 'react'
import { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Balances from '../../interfaces/Balances';
import FigureDetail from '../../interfaces/FigureDetail';
import Figure from '../Figure';
import Vial from '../Vial';
import ElementBalance from './ElementBalance';
import './ResourceBalance.scss';
interface IProps {
    balances:Balances|undefined;
}

const ResourceBalance = (props:IProps) => {
  const balances = props.balances || {} as Balances;
  
  //const [activeElements, setActiveElements] = React.useState<number[]>([]);

  const context = useContext(AppContext);
  const activeElements = context.activeElements || [];
  


  return (
    <div className={"resource-balance " + (activeElements.length > 0 ? 'active': '')}>
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(1)} rate={props.balances ? balances.resourceAccural?.deathRate : 0} modifier={props.balances ? balances?.modifiers?.[0] : 1} elementId={1} balance={balances.death} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(2)} rate={props.balances ? balances.resourceAccural?.lifeRate : 0} modifier={props.balances ? balances?.modifiers?.[1]: 1} elementId={2} balance={balances.life} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(3)} rate={props.balances ? balances.resourceAccural?.fireRate : 0} modifier={props.balances ? balances?.modifiers?.[2]: 1} elementId={3} balance={balances.fire} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(4)} rate={props.balances ? balances.resourceAccural?.waterRate : 0} modifier={props.balances ? balances?.modifiers?.[3]: 1} elementId={4} balance={balances.water} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(5)} rate={props.balances ? balances.resourceAccural?.earthRate : 0} modifier={props.balances ? balances?.modifiers?.[4]: 1} elementId={5} balance={balances.earth} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(6)} rate={props.balances ? balances.resourceAccural?.airRate : 0} modifier={props.balances ? balances?.modifiers?.[5]: 1} elementId={6} balance={balances.air} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(7)} rate={props.balances ? balances.resourceAccural?.memoryRate : 0} modifier={props.balances ? balances?.modifiers?.[6]: 1} elementId={7} balance={balances.memory} />
      <ElementBalance showRate={context.showRates || false} active={activeElements.includes(8)} rate={props.balances ? balances.resourceAccural?.visionRate : 0} modifier={props.balances ? balances?.modifiers?.[7]: 1} elementId={8} balance={balances.vision} />
    </div>
  )
}

export default ResourceBalance;