import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import TaskDetailsPage from '../../../../pages/TaskDetailsPage';
import FigureDisplay from '../../../FigureDisplay';
import TotalProgress from '../../../TotalProgress';
import BoardSlot from '../BoardSlot';
import './BlockBoard.scss';


interface IProps {
    taskBoard: TaskBoard | undefined;
    selectTaskSlot?(taskBoard: TaskBoard, slot: TaskBoardSlot): void;
    claimToken?(tokenId: number, taskBoardId: number, slotId: number): void;
}

const BlockBoard = (props: IProps) => {
    const context = useContext(AppContext);
    const session = context.userSession;
    const taskBoard = props.taskBoard;

    const totalSize = taskBoard?.slots.length || 0;
    const slotsFilled = taskBoard?.slots.filter((slot) => slot.token ? true : false).length;

    function getRowCount() {
        let columns = '';
        for (let i = 0; i < (taskBoard?.size || 1); i++) {
            columns = columns + "1fr ";
        }

        return columns.trim();
    }

    function getPercentage(): number {
        if (slotsFilled) {
            return (100 * slotsFilled) / totalSize;
        }
       return 0;
    }

    function selectTaskSlot(taskBoard: TaskBoard, slot: TaskBoardSlot): void {
        if (props.selectTaskSlot) {
            props.selectTaskSlot(taskBoard, slot)
        }
    }

    function claimToken(tokenId: number, taskBoardId: number, slotId: number): void {
        if (props.claimToken) {
            props.claimToken(tokenId, taskBoardId, slotId);
        }
    }

    return (
        <div className={'block-board ' + (taskBoard ? '' : 'empty')}>
            <div className="border"><span className="border-top"></span><span className="border-sides"></span><span className="border-bottom"></span></div>
            <div className='block-board-grid' style={{ gridTemplateColumns: getRowCount() }}>
                
                {taskBoard && taskBoard.slots.map((slot: TaskBoardSlot) => 
                    <div key={'slot-' + slot.id} className={'board-slot-socket ' + (!slot.token && slot.active ? 'slot-open' : '')} >
                        {slot.active && <BoardSlot hideUser={true} disableRemove={true} userInvolved={false} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={selectTaskSlot} claimToken={claimToken} />}
                    </div>
                )}
            </div>
            <div className='board-progress'> <TotalProgress percent={getPercentage()} /> <span className='totals'>{slotsFilled}/{totalSize}</span></div>
        </div>
    )
}

export default BlockBoard;