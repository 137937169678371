import React, { RefObject, useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Timer from './Countdown';
import Countdown from './Countdown';
import axios from 'axios';

// import audio from './audio';
import Viewer from './Viewer';
import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import Inventory from './components/Inventory';
import Figure from './components/Figure';
import FigureDetail from './interfaces/FigureDetail';
import { AppContext } from './AppContext';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ConnectPage from './pages/ConnectPage';
import UserBoard from './interfaces/UserBoard';
import ItemSwapPost from './interfaces/ItemSwapPost';
import Balances from './interfaces/Balances';
import Balance from './components/Balance';
import InventoryHoriz from './components/InventoryHoriz';
import User from './interfaces/User';

import UserSession from './interfaces/UserSession';
import AboutPage from './pages/AboutPage';
import UserManager from './components/UserManager';
import ExperimentNav from './components/ExperimentNav';
import ExperimentPage from './pages/ExperimentPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import WorldPage from './pages/WorldPage';
import UserNameChangePage from './pages/UserNameChangePage';
import TaskRankingsPage from './pages/TaskRankingsPage';
import TaskHistoryPage from './pages/TaskHistoryPage';
import TaskRewardsPage from './pages/TaskRewardsPage';
import ForgePage from './pages/ForgePage';
import MasteryPage from './pages/MasteryPage';

import SupplyPage from './pages/SupplyPage';
import ExpUserBoard from './interfaces/ExpUserBoard';
import ExpUserBoardSlot from './interfaces/ExpUserBoardSlot';

import paper1Sound from './audio/paper1.mp3';
import paper2Sound from './audio/paper2.mp3';
import paper3Sound from './audio/paper3.mp3';
import paper4Sound from './audio/paper4.mp3';
import paper5Sound from './audio/paper5.mp3';
import paper6Sound from './audio/paper6.mp3';
import useSound from 'use-sound';
import AudioControl from './components/AudioControl';
import MasteryDetailPage from './pages/MasteryDetailPage';
import DiscoveryPage from './pages/DiscoveryPage';

declare var window: any;

interface IProps {
  userSession: UserSession;
  connect(): void;
}


const App = (props: IProps) => {
  //const location = useLocation()
  var eventSource: EventSource | undefined;
  const auth: boolean = (props.userSession.user?.id ? true : false);
  const signature = props.userSession.signature;
  const originalMessage = props.userSession.message;
  const accountAddress = props.userSession.address;
  //const position = useMousePosition();
  // const AudioContext = window.AudioContext || window.webkitAudioContext;
  // const audioCtx = new AudioContext();
  

  //const [board, setBoard] = React.useState<(FigureDetail | undefined)[]>(buildBoard());

  const [userFigures, setUserFigures] = React.useState<FigureDetail[]>([]);
  const [userFigureMap, _setUserFigureMap] = React.useState<Map<number, FigureDetail>>(new Map<number, FigureDetail>());
  //const [boardRevision, setBoardRevision] = React.useState<number>(0);
  const [user, setUser] = React.useState<User>();
  const [saveMessage, setSaveMessage] = React.useState<string>('');
  const [showLoader, _setShowLoader] = React.useState<boolean>(false);
  const [userBoard, _setUserBoard] = React.useState<ExpUserBoard[]>([]);
  const [effectsVolume, _setEffectsVolume] = React.useState<number>(getDefaultStateNumber('effectsVolume') || 0.5);
  const [effectsMuted, _setEffectsMuted] = React.useState<boolean>(getDefaultStateBoolean('effectsMuted'));
  function useBooleanState(initialValue:any) {
  const [value, setValue] = React.useState<boolean>(false);
  const setTrue = useCallback(() =>setValue(true), [])
  const setFalse = useCallback(() =>setValue(false), [])
  return [value, setTrue, setFalse];

}
const [activeElements, setActiveElements] = React.useState<number[]>([]);
const [showRates, setShowRates] = React.useState<boolean>(false);
const[playing, isPlaying, isNotPlaying] = useBooleanState(false);



  const [balances, setBalances] = React.useState<Balances>({} as Balances);


  function setShowLoader(message: string, show: boolean): void {
    setSaveMessage(message);
    _setShowLoader(show);
  }

  function getDefaultStateString(key: string): string {
    let string = localStorage.getItem(key)
    return string ? JSON.parse(string) : '';
  }

  function getDefaultStateNumber(key: string): number | undefined {
    let string = localStorage.getItem(key)
    return string ? Number(JSON.parse(string)) : undefined;
  }
  

  function getDefaultStateBoolean(key: string): boolean {
    let string = localStorage.getItem(key)
    return string ? Boolean(JSON.parse(string)) : false;
  }

  React.useEffect(() => {
  

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/world/settings`;
    axios({
      "method": "GET",
      "url" : url
    })
    .then((response) => {
      setActiveElements(response.data.activeElements);
      setShowRates(response.data.showRates);
    })
    .catch((error) => {
      console.log(error);
    })
 
 }, []);

 React.useEffect(() => {
  
  if (signature) {
  let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getDreg`;
  axios({
    "method": "GET",
    "url" : url,
    "headers": {
      "signedMessage": signature as string,
      "originalMessage": originalMessage as string,
      "address": accountAddress as string
    }
  })
  .then((response) => {
    //console.log(response.data);
  })
  .catch((error) => {
    console.log(error);
  })
}
}, [auth]);

  const [placeFigureSound1] = useSound(
    paper1Sound,
    { volume: effectsVolume }
  );
  const [placeFigureSound2] = useSound(
    paper2Sound,
    { volume: effectsVolume }
  );
  const [placeFigureSound3] = useSound(
    paper3Sound,
    { volume: effectsVolume }
  );
  const [placeFigureSound4] = useSound(
    paper4Sound,
    { volume: effectsVolume }
  );
  const [placeFigureSound5] = useSound(
    paper5Sound,
    { volume: effectsVolume }
  );
  const [placeFigureSound6] = useSound(
    paper6Sound,
    { volume: effectsVolume }
  );

  function playRandomPaperSound() {
    if(!effectsMuted) {
      let sound = getRandomInt(6);
      if(sound === 0) {
        placeFigureSound1();
      } else if (sound === 1) {
        placeFigureSound2();
      } else if (sound === 2) {
        placeFigureSound3();
      } else if (sound === 3) {
        placeFigureSound3();
      } else if (sound === 4) {
          placeFigureSound5();
      } else if (sound === 5) {
        placeFigureSound6();
      }
    }
  }

  function setEffectsMuted(muted:boolean) {
    //console.log(muted);
    localStorage.setItem("effectsMuted", String(muted));
     _setEffectsMuted(muted);
   }

   function setEffectsVolume(volume:number) {
    localStorage.setItem("effectsVolume", String(volume));
    _setEffectsVolume(volume);
   }

  function getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }

  function setUserBoard(updatedBoard:ExpUserBoard) {
    let updatedUserBoards = userBoard;
    for (let i = 0; i < updatedUserBoards.length; i++) {
      if (updatedUserBoards[i].id === updatedBoard.id) {
        updatedUserBoards[i] = updatedBoard;
      }
    }
    _setUserBoard([... updatedUserBoards]);
  }

  React.useEffect(() => {

    if (signature) {

      updateUserFigures();
      

      getUserBoard();

      let getUserUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getUser`;
      
      axios({
        "method": "GET",
        "url": getUserUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          //console.log(response.data);
          let getUser: User = response.data;
          
          setUser(getUser);
        })
        .catch((error) => {
          console.log(error)
        })


        let getUserBalanceUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBalance`;

      axios({
        "method": "GET",
        "url": getUserBalanceUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          let getBalance:Balances = response.data;
          setBalances(getBalance);
        })
        .catch((error) => {
          console.log(error)
        })

    }


  }, [auth]);


  function getUserBoard() {
    if (signature) {
    let boardUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getBoard2`;

      axios({
        "method": "GET",
        "url": boardUrl,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
      })
        .then((response) => {
          let userBoard:ExpUserBoard[] = response.data;
          _setUserBoard(userBoard);

        })
        .catch((error) => {
          console.log(error)
        })
      }
  }
  

  function directUpdateUserBoard(slot:ExpUserBoardSlot, figure:FigureDetail) {
    playRandomPaperSound();
    let updatedUserBoard = userBoard || [];
    let currentSlot:ExpUserBoardSlot|undefined;
    let updatedSlot:ExpUserBoardSlot|undefined;
    for (let b = 0; b < updatedUserBoard.length; b++) {
    for (let i = 0; i < updatedUserBoard[b].slots.length; i++) {
      if (updatedUserBoard[b].slots[i].id === slot.id) {
        updatedSlot = updatedUserBoard[b].slots[i];
      }
      if (updatedUserBoard[b].slots[i].token?.id === figure.id) {
        currentSlot = updatedUserBoard[b].slots[i];
      }
    }
    }
    if(currentSlot) {
      currentSlot.token = undefined;
    }
    if(updatedSlot?.token && currentSlot && currentSlot.type != 'focus') {
      if(currentSlot.slotRequirement.startsWith('tier')) {
        let slotTier = Number(currentSlot.slotRequirement.replace('tier',''));
        if (updatedSlot.token.tier <= slotTier) {
          currentSlot.token = updatedSlot.token;
        }
      }
      
    }
    if(updatedSlot) {
      updatedSlot.token = figure;
    }
    _setUserBoard(updatedUserBoard);
  }

  function directRemoveUserBoard(figure:FigureDetail) {
    let updatedUserBoard = userBoard || [];
    let currentSlot:ExpUserBoardSlot|undefined;
    for (let b = 0; b < updatedUserBoard.length; b++) {
      for (let i = 0; i < updatedUserBoard[b].slots.length; i++) {
        if (updatedUserBoard[b].slots[i].token?.id === figure.id) {
          currentSlot = updatedUserBoard[b].slots[i];
        }
      }
    }
    if(currentSlot) {
      currentSlot.token = undefined;
    }
    _setUserBoard(updatedUserBoard);
  }

  function placeFigureOnUserBoard(slot:ExpUserBoardSlot, figure:FigureDetail) {
    
    directUpdateUserBoard(slot, figure);

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/setBoard2`;
    
    axios({
      "method": "POST",
      "url": url,
      "headers": {
          "signedMessage": signature,
          "originalMessage": originalMessage,
          "address": accountAddress,
          'content-type': 'application/json'
      },
      "data": {userBoardSlotId: slot.id, tokenId: figure.id}

    })
      .then((response) => {
         // let updatedUser:User = response.data;
          //let updatedBoard = response.data;
          //setUserBoard(updatedBoard);
          //context.setShowLoader?.(`Minting ${mintableToken.tokenDetail.name} ${mintableToken.tokenDetail.edition}/${mintableToken.tokenDetail.totalEditions}`, false);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  function removeFigureFromUserBoard(figure:FigureDetail) {
    directRemoveUserBoard(figure);

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/claimBoardToken`;
    
    axios({
      "method": "POST",
      "url": url,
      "headers": {
          "signedMessage": signature,
          "originalMessage": originalMessage,
          "address": accountAddress,
          'content-type': 'application/json'
      },
      "data": {userBoardSlotId: 1, tokenId: figure.id}

    })
      .then((response) => {
         // let updatedUser:User = response.data;
          //let updatedBoard = response.data;
          //setUserBoard(updatedBoard);
          //context.setShowLoader?.(`Minting ${mintableToken.tokenDetail.name} ${mintableToken.tokenDetail.edition}/${mintableToken.tokenDetail.totalEditions}`, false);
      })
      .catch((error) => {
          console.log(error);
      })
  }

  function updateUserFigures() {

    let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/getFigures`;


    axios({
        "method": "GET",
        "params": { criteria: 'all=true' },
        "url": url,
        "headers": {
          "signedMessage": signature as string,
          "originalMessage": originalMessage as string,
          "address": accountAddress as string
        }
    })
        .then((response) => {
            let figures: FigureDetail[] = response.data;
            setUserFigures(figures);
            //console.log('user figures updated');
            //context.setShowLoader?.(`Loading Figures`, false);
        })
        .catch((error) => {
            console.log(error);
            //context.setShowLoader?.(`Loading Figures`, false);
        });
  }


  React.useEffect(() => {



    if (props.userSession.user?.id != null) {
      eventSource = new EventSource(`${process.env.REACT_APP_SERVICE_BASEPATH}/user/${props.userSession.user.id}/events`);

      eventSource.onmessage = e => setBalances(JSON.parse(e.data));

    }


    return () => {
      eventSource?.close();

    }



  }, [props.userSession.user?.id])



  return (
    <div className={"the-gold-crow fade-in " + (auth ? 'auth' : '')} >
      {/* <button onClick={() => setChecked(true)}>Play Sound</button>
      <button onClick={() => stopSound()}>Stop Sound</button> */}
      <AppContext.Provider value={{ activeElements: activeElements, showRates : showRates, setEffectsVolume : setEffectsVolume, effectsVolume : effectsVolume, effectsMuted: effectsMuted, setEffectsMuted: setEffectsMuted, placeFigureOnUserBoard: placeFigureOnUserBoard, removeFigureFromUserBoard : removeFigureFromUserBoard, userBoard : userBoard, updateUserBoard : setUserBoard, setShowLoader: setShowLoader, setUser: setUser, user: user, userSession: props.userSession, auth: auth, connect: props.connect, board: undefined, userFigureMap: userFigureMap, swap: undefined, balances: balances, setUserBalance: setBalances, updateBoard : undefined, userFigures : userFigures, updateUserFigures: updateUserFigures }} >
      <AudioControl />
        <div className={"page-pane " + (auth ? 'auth' : '')}>
        
          <BrowserRouter>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="discovery/:id" element={<DiscoveryPage />} />
              <Route path="experiment" element={<ExperimentPage />}>
                <Route index element={<UserPage balances={balances} />} />
                <Route path="world" element={<WorldPage />} />
                <Route path="world/:taskAlias/rankings" element={<TaskRankingsPage />} />
                <Route path="world/:taskAlias/history" element={<TaskHistoryPage />} />
                <Route path="world/:taskAlias/rewards" element={<TaskRewardsPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="profile/change-name" element={<UserNameChangePage />} />
                <Route path="forge" element={<ForgePage />} />
                <Route path="research" element={<MasteryPage />} />
                <Route path="research/:elementAlias" element={<MasteryDetailPage />} />
                <Route path="supply" element={<SupplyPage />} />
              </Route>
              <Route path="/figure/:figureNumber/edition/:editionNumber/" element={<Viewer />} />
            </Routes>
          </BrowserRouter>
          {/* {dragItem && <div className={'drag-item ' + (dragItem ? 'show' : '')} style={{ transform: `translateX(${offset.x}px) translateY(${offset.y}px)`, left: position.x, top: position.y }}>
            <div className='drag-wrapper'>
              <Figure proxy={true} position={-1} figure={dragItem} />
            </div>
          </div>} */}
        </div>
        
      </AppContext.Provider>
      {showLoader && <div className='loading-indicator fade-in-fast pulse show'>
        <div className="pulse-loader"></div>
        {saveMessage}</div>}
    </div>
  );
  
}

export default App;

