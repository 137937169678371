import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import FigureDisplay from '../../../FigureDisplay';
import './BoardSlot.scss';


interface IProps {
    taskBoard:TaskBoard;
    taskBoardSlot:TaskBoardSlot;
    userInvolved:boolean;
    selectTaskSlot(taskBoard:TaskBoard, slot:TaskBoardSlot):void;
    claimToken(tokenId:number, taskBoardId:number, slotId:number):void;
    hideUser?:boolean;
    disableRemove?:boolean;
}

const BoardSlot = (props:IProps) => {
    const context = useContext(AppContext);
    const session = context.userSession;
    const taskBoard = props.taskBoard;
    const slot = props.taskBoardSlot;


    

    function onSlotClick() {
        if(context.auth && !slot.token && !props.userInvolved) {
            props.selectTaskSlot(taskBoard, slot);
        }
    }

return (
   
                                    <div className={'board-slot board-slot-' + slot.boardLocation}>
                                        <div className={'slot-hit-box ' + (!slot.token && !props.userInvolved ? 'open' : '')} onClick={onSlotClick}>
                                            {slot.token && <div className={'slot-power-glow slot-power element-' + slot.token.elementId}></div>}
                                        <div className={'circle-slot ' + (!slot.token ? 'open' : '')} >
                                            {!slot.token && slot.placeholderToken && <FigureDisplay token={slot.placeholderToken} size={'quarter'} />}
                                            {slot.token && !slot.token.mask && <FigureDisplay token={slot.token} size={'quarter'} />}
                                            {slot.token && slot.token.mask && <div className='masked'><FigureDisplay token={slot.placeholderToken} size={'quarter'} />
                                            <FigureDisplay token={slot.token} size={'quarter'} /></div>}
                                        </div>
                                        </div>
                                        {slot.token && slot.token.userId === session?.user?.id && !props.disableRemove &&
                                                <button onClick={() => props.claimToken(slot.token.id, taskBoard.id, slot.id)}>
                                                <svg viewBox="0 0 24 24">
<path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
</svg>
                                            </button>
                                            }
                                            {slot.token && !props.hideUser && <div className={'token-user ' + (context.auth && slot.token.userId == session?.user.id ? 'is-user' : '')}>{slot.token.userName}</div>}
                                    </div>
                                
  )
}

export default BoardSlot;