import axios from 'axios';
import { totalmem } from 'os';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Balances from '../../../interfaces/Balances';
import ElementTransaction from '../../../interfaces/ElementTransaction';
import ExpUserBoard from '../../../interfaces/ExpUserBoard';
import RewardToken from '../../../interfaces/RewardToken';
import SetTaskBoardPost from '../../../interfaces/SetTaskBoardPost';
import Task from '../../../interfaces/Task';
import TaskBoard from '../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../interfaces/TaskBoardSlot';
import TaskContributionElement from '../../../interfaces/TaskContributionElement';
import TransferConfirmation from '../../../interfaces/TransferConfirmation';
import UserBoard from '../../../interfaces/UserBoard';
import UserTaskRank from '../../../interfaces/UserTaskRank';
import TaskDetailsPage from '../../../pages/TaskDetailsPage';
import FigureDisplay from '../../FigureDisplay';
import FigureSelect from '../../FigureSelect';
import LongTimer from '../../LongTimer';
import ModalButtonBar from '../../ModalButtonBar';
import ModalChoice from '../../ModalChoice';
import ModalContainer from '../../ModalContainer';
import Timer from '../../Timer/Timer';
import TotalProgress from '../../TotalProgress';
import BlockBoard from './BlockBoard';
import BlockBoardHistory from './BlockBoardHistory';
import BlockBoardManager from './BlockBoardManager';
import CircleBloodPact from './CircleBloodPact';
import CompletedSet from './CompletedPact';


import './WorldBoard.scss';
interface IProps {
    task: Task;
    onTaskUpdate(task: Task): void;
    refreshTask(taskAlias:string):void;
}

const WorldBoard = (props: IProps) => {

    const context = useContext(AppContext);
    const session = context.userSession;
    const balances = context.balances;
    const [selectedBoard, setSelectedBoard] = React.useState<TaskBoard|undefined>();
    const [selectedSlot, setSelectedSlot] = React.useState<TaskBoardSlot|undefined>();
    const [selectedElement, setSelectedElement] = React.useState<number | undefined>(undefined);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [transferAmount, setTransferAmount] = React.useState<string>('0');
    const [showUserRank, setShowUserRank] = React.useState<boolean>(context.auth || false);
    const [userTaskRewards, setUserTaskRewards] = React.useState<RewardToken[]>([]);
    const [rewardsLoaded, setRewardsLoaded] = React.useState<boolean>(false);
    //const [rankedList, setRankedList] = React.useState<UserTaskRank[]>(getRankList(true));
    //const [userRankNumber, setUserRankNumber] = React.useState<number>(getUserRankNumber());
    const style = { "--task-background-image": `url('https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/${props.task.backgroundImageKey}/taskbackground')` } as React.CSSProperties;

    


    function placeToken(token: number):void {
        
        let setTaskBoard = {} as SetTaskBoardPost;
        setTaskBoard.taskBoardId = selectedBoard!.id;
        setTaskBoard.taskBoardSlotId = selectedSlot!.id;
        setTaskBoard.tokenId = token;
        removeSelection();

        if (selectedBoard && selectedSlot) {
            let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/setTaskBoard`;
            setSaving(true);
            context.setShowLoader?.('Placing Figure', true);
            axios({
                "method": "POST",
                "url": url,
                "headers": {
                    "signedMessage": session?.signature as string,
                    "originalMessage": session?.message as string,
                    "address": session?.address as string,
                    'content-type': 'application/json'
                },
                "data": setTaskBoard

            })
                .then((response) => {
                    setSaving(false);
                    let updatedUserBoard:ExpUserBoard = response.data;
                    context.updateUserBoard?.(updatedUserBoard);
                    context.updateUserFigures?.();
                    context.setShowLoader?.('Placing Figure', false);
                    
                })
                .catch((error) => {
                    console.log(error);
                    
                    props.refreshTask(props.task.alias);
                    setSaving(false);
                    context.setShowLoader?.('Placing Figure', false);
                })
        }
    }

    function claimToken(token:number, boardId:number, slotId:number):void {
        //removeSelection();
        let setTaskBoard = {} as SetTaskBoardPost;
        setTaskBoard.taskBoardId = boardId;
        setTaskBoard.taskBoardSlotId = slotId;
        setTaskBoard.tokenId = token;

        
            let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/claimToken`;
            setSaving(true);
            context.setShowLoader?.('Removing Figure', true);
            axios({
                "method": "POST",
                "url": url,
                "headers": {
                    "signedMessage": session?.signature as string,
                    "originalMessage": session?.message as string,
                    "address": session?.address as string,
                    'content-type': 'application/json'
                },
                "data": setTaskBoard

            })
                .then((response) => {
                    setSaving(false);
                    let updatedUserBoard:ExpUserBoard = response.data;
                    context.updateUserBoard?.(updatedUserBoard);
                    context.updateUserFigures?.();
                    context.setShowLoader?.('Removing Figure', false);
                    //removeSelection();
                })
                .catch((error) => {
                    console.log(error);
                    //removeSelection();
                    props.refreshTask(props.task.alias);
                    setSaving(false);
                    context.setShowLoader?.('Removing Figure', false);
                })
        
    }


    function selectTaskSlot(board:TaskBoard, slot:TaskBoardSlot) {
        setSelectedBoard(board);
        setSelectedSlot(slot);
    }

    function removeSelection() {
        setSelectedBoard(undefined);
        setSelectedSlot(undefined);
    }

    function onPactFail() {
        if (props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1)[0].expirationSeconds > 0) {
            props.refreshTask(props.task.alias);
        }
        
    }

    return (
        <div className={"world-board fade-in task-" + props.task.id + " " + (props.task.percentComplete >= 100 ? 'complete' : '')} style={style}>
            <div className='experiment-content'>
                <div className='task-description'>
                    <div className='task-detail'>{props.task.subtitle}</div>
                    <h1>{props.task.name}</h1>
                    <div className='task-menu'>
                        <ul>
                            <li><Link to={`${props.task.alias}/rewards`}>Rewards</Link></li>
                            <li><Link to={`${props.task.alias}/history`}>History</Link></li>
                        </ul>
                    </div>
                    
                    {props.task.percentComplete < 100 && <TotalProgress percent={props.task.percentComplete} />}
                    {props.task.percentComplete >= 100 && <span className='complete-tag'>TASK COMPLETE {new Date(props.task.endDate).toLocaleString(window.navigator.language)}</span>}
                    {props.task.type === 2 &&<div className='sets-complete'>{props.task.totalSets}/{props.task.maxSets} Pacts Completed</div>}
                    {props.task.type === 3 &&<div className='sets-complete'>{props.task.totalSets}/{props.task.maxSets} Evidence Collected</div>}
                    <div className='task-end'>Task Ends: {new Date(props.task.endDate).toLocaleString(window.navigator.language)}</div>
                    <p>{props.task.description}</p>
                    {props.task.type === 2 &&
                    <div>
                        Figures used to form a Blood Pact will be locked in the pact until the task ends.
                    </div>}
                    {props.task.type === 3 &&
                    <div>
                        Figures provided as evidence will be locked until the task ends.
                    </div>}


                </div>
                <div className={'world-board-wrapper ' + (saving ? 'saving' : '')}>
                    {props.task.type === 2 &&
                    <div>
                        {props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1).map((taskBoard:TaskBoard) => 
                            <div key={'board-' + taskBoard.id}>
                                <div className={'pact-timer ' + (taskBoard.expirationSeconds <= 0 ? 'board-open' : '')}>Pact Fails in <LongTimer key={'time' + taskBoard.expirationSeconds} onTimerEnd={onPactFail} initialSeconds={taskBoard.expirationSeconds} /></div>
                                <CircleBloodPact taskBoard={taskBoard} selectTaskSlot={selectTaskSlot} claimToken={claimToken} />
                            </div>
                        )}
                        {props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1).length == 0 &&  
                            <div key={'board-lock'}>
                                <CircleBloodPact taskBoard={undefined} selectTaskSlot={selectTaskSlot} claimToken={claimToken} />
                            </div>
                        }
                    </div>}
                    {props.task.type === 3 &&
                    <div>
                        {props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1).map((taskBoard:TaskBoard) => 
                            <div key={'board-' + taskBoard.id}>
                                <div className={'block-board-timer pact-timer ' + (taskBoard.expirationSeconds <= 0 ? 'board-open' : '')}>Experiment Restarts in <LongTimer key={'time' + taskBoard.expirationSeconds} onTimerEnd={onPactFail} initialSeconds={taskBoard.expirationSeconds} /></div>
                                <BlockBoard taskBoard={taskBoard} selectTaskSlot={selectTaskSlot} claimToken={claimToken} />
                            </div>
                        )}
                        {props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1).length == 0 &&  
                            <div key={'board-lock'}>
                                <BlockBoard taskBoard={undefined} selectTaskSlot={selectTaskSlot} claimToken={claimToken} />
                            </div>
                        }
                    </div>}
                </div>

                {props.task.type === 3 && props.task.status === 1 &&<div className='rank-list-wrapper'>
                <div className='task-section-heading'>
                    <h2>Recent Evidence</h2>
                    <span></span>
                </div>
                   <BlockBoardHistory taskBoard={props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1)[0]} />                 
                </div>}

                
            </div>
            {props.task.type == 3 && props.task.status === 1 &&  <div className='completed-sets-wrapper'>
            <div className='task-section-heading'>
                    <h2>Manage Evidence</h2>
                    <span></span>
                </div>
            <div className='completed-sets'>
            <BlockBoardManager taskBoard={props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status === 1)[0]}  selectTaskSlot={selectTaskSlot} claimToken={claimToken} />
            </div>
            </div>}
            {props.task.type === 2 && Boolean(props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status >= 2).length > 0) && 
            <div className='completed-sets-wrapper'>
            <div className='task-section-heading'>
                    <h2>Completed Pacts ({props.task.totalSets}/{props.task.maxSets})</h2>
                    <div className='task-section-nav'>
                    <Link to={`${props.task.alias}/history`}>View All</Link>
                    </div>
                    <span></span>
                </div>
            <div className='completed-sets'>
            
            
                <ul>
                        
                        {props.task.taskBoards.filter((taskBoard:TaskBoard) => taskBoard.status >= 2).reverse().slice(0, 8).map((taskBoard:TaskBoard) => 
                            <li key={'taskboard-' + taskBoard.id}>
                                <CompletedSet taskBoard={taskBoard} />
                            </li>
                        )}
                        </ul>
            
                    
                    </div>
                    </div>}
            {selectedBoard && selectedSlot && ReactDOM.createPortal(
                <FigureSelect criteria={selectedSlot.slotRequirement} onSelect={placeToken} onClose={removeSelection} />
                , document.body)
            }
            
        </div>
    )
}

export default WorldBoard;