import { Fragment, useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import TaskBoard from '../../../../interfaces/TaskBoard';
import TaskBoardSlot from '../../../../interfaces/TaskBoardSlot';
import FigureDetail from '../../../FigureDetail';
import IFigureDetail from '../../../../interfaces/FigureDetail';
import FigureDisplay from '../../../FigureDisplay';
import BoardSlot from '../BoardSlot';
import './BlockBoardManager.scss';
import React from 'react';


interface IProps {
    taskBoard: TaskBoard | undefined;
    selectTaskSlot(taskBoard: TaskBoard, slot: TaskBoardSlot): void;
    claimToken(tokenId: number, taskBoardId: number, slotId: number): void;
}

const BlockBoardManager = (props: IProps) => {
    const context = useContext(AppContext);
    const session = context.userSession;
    const taskBoard = props.taskBoard;

    const userFigures:IFigureDetail[] = context.userFigures || []; 

    

    const [showAll, setShowAll] = React.useState<boolean>(false);
    const [showProvided, setShowProvided] = React.useState<boolean>(false);
    function inventoryContains(token:IFigureDetail):boolean {

        if (userFigures.filter((uToken) => uToken.figureId === token.figureId)[0]) {
            return true;
        }
        return false;
    }

    function inventoryContainsMask(token:IFigureDetail):boolean {
        
        if (userFigures.filter((uToken) => uToken.mask && uToken.elementId === token.elementId)[0]) {
            return true;
        }
        return false;
    }

    function inventoryContainsExact(token:IFigureDetail):boolean {

        if (userFigures.filter((uToken) => uToken.id === token.id)[0]) {
            return true;
        }
        return false;
    }

    const matchingFigures = taskBoard ? taskBoard.slots.filter((slot:TaskBoardSlot) => (!slot.token && slot.active && inventoryContains(slot.placeholderToken) || (!slot.token && slot.active && inventoryContainsMask(slot.placeholderToken)) || (slot.token && slot.token.id !== slot.placeholderToken.id && inventoryContainsExact(slot.placeholderToken)) || (slot.token && slot.token.mask && inventoryContains(slot.placeholderToken))  )) : [];

    return (
        <div className='block-board-manager'>
            <div className='supply-filters'>
        {context.auth && <><div className='supply-filter'>
        <label className={showAll ? 'active' : ''}>
        <input onChange={(e) => setShowAll(!showAll)} type="checkbox" checked={showAll} />
          <span className='check-box'></span> Show Required Evidence
          </label>
        </div>
        <div className='supply-filter'>
        <label className={showProvided ? 'active' : ''}>
        <input onChange={(e) => setShowProvided(!showProvided)} type="checkbox" checked={showProvided} />
          <span className='check-box'></span> Show Provided Evidence
          </label>
        </div>
        </>}
      </div>
            {context.auth && taskBoard && matchingFigures.length === 0 && <div className='board-empty'>
                Your Figures do not match any of the required evidence...
            </div>}
            <ul>
            {taskBoard && matchingFigures.map((slot: TaskBoardSlot) => <Fragment key={'slot-' + slot.id}>
                    
                    <li>
                        <div className='board-item fade-in'>
                        <BoardSlot hideUser={true}  userInvolved={false} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                        <FigureDetail figure={slot.placeholderToken}/>
                        <span className={'owned-by '  + (context.auth && slot.placeholderToken.userId === (session?.user.id || 0) ? 'is-user' : '')}>
                            <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.placeholderToken.userName}</span>
                        <div className='button-footer'><button className='mint-button' onClick={() => props.selectTaskSlot(taskBoard, slot)}>Place</button></div>
                        </div>
                        </li>
                    
                </Fragment>
                )}
            {taskBoard && taskBoard.slots.map((slot: TaskBoardSlot) => <Fragment key={'slot-' + slot.id}>
                    {(slot.token && slot.token.userId === session?.user?.id) &&
                    <li>
                        <div className='board-item fade-in'>
                        <BoardSlot hideUser={true}  userInvolved={false} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                        <FigureDetail figure={slot.token ? slot.token : slot.placeholderToken}/>
                        <span className={'owned-by is-user'}>
                            <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.token.userName}</span>
                            {slot.placeholderToken.id !== slot.token.id && <span className={'owned-by not-user'}>
                            <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.placeholderToken.userName}</span>}
                        <div className='button-footer'><button className='mint-button' onClick={() => props.claimToken(slot.token.id, taskBoard.id, slot.id)}>Remove</button></div>
                        </div>
                        </li>
                    }
                    
                </Fragment>)}
                
                {(!context.auth || showAll) && taskBoard && taskBoard.slots.map((slot: TaskBoardSlot) => <Fragment key={'slot-' + slot.id}>
                    {(!slot.token && slot.active && !inventoryContains(slot.placeholderToken)) &&
                    <li>
                        <div className='board-item disabled fade-in'>
                        <BoardSlot hideUser={true}   userInvolved={false} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                        <FigureDetail figure={slot.token ? slot.token : slot.placeholderToken} />
                        <span className={'owned-by '}>
                            <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.placeholderToken.userName}</span>
                        <div className='button-footer'><button disabled={true} className='mint-button' onClick={() => props.selectTaskSlot(taskBoard, slot)}>Place</button></div>
                        </div>
                        </li>
                    }
                </Fragment>
                )}

                {showProvided && taskBoard && taskBoard.slots.map((slot: TaskBoardSlot) => <Fragment key={'slot-' + slot.id}>
                    {(slot.token && slot.token.userId !== session?.user?.id) &&
                    <li>
                        <div className='board-item fade-in'>
                        <BoardSlot hideUser={true}  userInvolved={false} key={'slot' + slot.id} taskBoard={taskBoard} taskBoardSlot={slot} selectTaskSlot={props.selectTaskSlot} claimToken={props.claimToken} />
                        <FigureDetail figure={slot.token && !slot.token.mask ? slot.token : slot.placeholderToken}/>
                        <span className={'owned-by '}>
                        <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.token.userName}</span>
                        {slot.placeholderToken.userId !== slot.token.userId && <span className={'owned-by '  + (context.auth && slot.placeholderToken.userId === (session?.user.id || 0) ? 'is-user' : 'not-user')}>
                            <span className='special'>
                                <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M12,6.7L13.45,10.55L17.3,12L13.45,13.45L12,17.3L10.55,13.45L6.7,12L10.55,10.55L12,6.7M12,1L9,9L1,12L9,15L12,23L15,15L23,12L15,9L12,1Z" />
                                </svg>
                            </span>
                            {slot.placeholderToken.userName}</span>}
                        <div className='button-footer'></div>
                        </div>
                        </li>
                    }
                    
                </Fragment>)}
            </ul>
            
        </div>
    )
}

export default BlockBoardManager;