import React from 'react';
import { useState, useEffect } from 'react';

interface IProps {
    initialSeconds:number;
    onTimerEnd?():void;
    setSecondsLeft?(seconds:number):void;
    disableCountdown?:boolean;
}

const ResearchTimer = (props:IProps) => {

    const [secondsLeft, setSecondsLeft] = React.useState(props.initialSeconds);
  
    const days = Math.floor(secondsLeft/24/60/60);
    const hoursLeft = Math.floor((secondsLeft) - (days*86400));
    const hours = Math.floor(hoursLeft/3600);
    const minutesLeft = Math.floor((hoursLeft) - (hours*3600));
    const minutes = Math.floor(minutesLeft/60);
    const seconds = secondsLeft % 60;

    React.useEffect(() => {

      if(!props.disableCountdown) {
        const interval = setInterval(() => {
          if (secondsLeft == 0) {
            clearInterval(interval);
            props.onTimerEnd?.();
          } else {
            setSecondsLeft(secondsLeft - 1);
            if(props.setSecondsLeft) {
              props.setSecondsLeft(secondsLeft - 1);
            }
          }
        }, 1000);
        return () => clearInterval(interval)
      }
      
      
    }, [secondsLeft])

    React.useEffect(() => {
      
      setSecondsLeft(props.initialSeconds);
      
      
    }, [props.initialSeconds])

    return (
        <div className="research-timer">
        <div className="panel">
          <span>DAYS</span>
          <span>{days < 10 ? "0" + days : days}</span>
        </div>
        <div className="panel">
          <span>HOURS</span>
          <span>{hours < 10 ? "0" + hours : hours}</span>
        </div>
        <div className="panel">
          <span>MINS</span>
          <span>{minutes < 10 ? "0" + minutes : minutes}</span>
        </div>
        <div className="panel">
          <span>SECS</span>
          <span>{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
      </div>
    )
}

export default ResearchTimer;