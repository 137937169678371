import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import FigureDetail from '../../components/FigureDetail';
import FigureDisplay from '../../components/FigureDisplay';
import Discovery from '../../interfaces/Discovery';
import DiscoveryService from '../../services/DiscoveryService';
import ResearchTimer from '../../components/ResearchTimer';
import './DiscoveryPage.scss';

interface IProps {
  
}

const DiscoveryPage = (props:IProps) => {

  const context = React.useContext(AppContext);
  let { id } = useParams();
  const [discovery, setDiscovery] = React.useState<Discovery>();
  const [nameOrAddress, setNameOrAddress] = React.useState<string>('');
  const [formError, setFormError] = React.useState<string>();
  const [pageError, setPageError] = React.useState<string>();
  const [claimMessage, setClaimMessage] =React.useState<string>();
  const user = context.user;

  React.useEffect(() => {
    
      if (id) {
        context.setShowLoader?.(`Loading Discovery`, true);
        DiscoveryService.findByClaimCode(id).then((discoveryResponse) => {
            setDiscovery(discoveryResponse);
            context.setShowLoader?.(`Loading Discovery`, false);
        })
        .catch((error) => {
          if( error.response ){
            setPageError(error.response.data);
          } else {
            console.log(error);
          }
            context.setShowLoader?.(`Loading Discovery`, false);
        });
      }

  }, []);

  React.useEffect(() => {
    

  }, [nameOrAddress]);

  function claimDiscovery(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (id && nameOrAddress) {
      context.setShowLoader?.(`Claiming Discovery`, true);
      DiscoveryService.claimDiscovery(id, nameOrAddress).then((claimResponse) => {
          setDiscovery(claimResponse);
          setClaimMessage("You have claimed this discovery!");
          context.setShowLoader?.(`Claiming Discovery`, false);
      })
      .catch((error) => {
          if( error.response ){
            setFormError(error.response.data);
          } else {
            console.log(error);
          }
          context.setShowLoader?.(`Claiming Discovery`, false);
      });
    }
  }

  return (
    <div className="discovery-page fade-in">
        <div className='discovery-header'>
          <div className='discovery-wrapper'>
            <img className='the-gold-crow' src="/the-gold-crow.png" />
            <img className='discovery' alt='Discovery' src="/discovery.png" />
            <div className='discovery-nav'>
        <ul><li><Link to={'/about'}>About</Link></li>
        <li><Link to={"/experiment"}>Experiment</Link></li>
        <li><a href={"https://t.me/the_gold_crow"}>Telegram</a></li>
        <li><a href={"https://discord.gg/xybcszGygW"}>Discord</a></li>
        </ul></div>
          </div>
          
        </div>
        {!discovery && pageError && <div className='discovery-wrapper fade-in'><div className='page-error-message'>{pageError}
        </div></div>}
      {discovery && <><div className='discovery-wrapper fade-in'>
          <div className='discovery-body'>
          <div className='discovery-details'>
            <div>
            <h1>{discovery.action}</h1>
            <p>{discovery.actionDescription}</p>
            <div className='status-wrapper'>
              <span className='status-tag'>Discovery {discovery.status === 'active' ? 'Unclaimed' : discovery.status}</span>
              </div>
            </div>
          </div>
          {claimMessage && <div className='claim-message fade-in'>{claimMessage}</div>}
          {discovery.status === 'active' && <>
          <div className='discovery-claim'>
            <form onSubmit={(e) => claimDiscovery(e)}>
           <label htmlFor='nameOrAddress'>What is Your Undying Name or Wallet Address?</label>
           {user && <button type="button" className='connected-user-button' onClick={() => setNameOrAddress(user.name)}>Use {user.name} (connected wallet)</button>}
           {formError && <div className='form-error-message'>{formError}</div>}
           <input id='nameOrAddress' type='text' onChange={(e) => setNameOrAddress(e.target.value || '')} value={nameOrAddress} />
           <div className='button-wrapper'>
           <button disabled={nameOrAddress.length == 0} className='claim-button'>claim discovery</button>
           </div>
           </form>
           </div>
           <div className='discovery-expire'><span className='expires-label'>Expires In</span> <ResearchTimer initialSeconds={Math.trunc(discovery.expirationSeconds / 1000)} /></div>
           </>}
          
          <div className='discovery-reward'>
          <FigureDisplay token={discovery.tokenDetail} size={'half'} />
          <FigureDetail figure={discovery.tokenDetail} />
          </div>
          
           <table>
             <thead>
               <tr>
               <th>Date/Time</th>
               <th>Event</th>
               </tr>
             </thead>
             <tbody>
             {discovery.status === 'expired' &&<tr>
                <td>{new Date(discovery.expirationDate).toLocaleDateString()} <span>{new Date(discovery.expirationDate).toLocaleTimeString()}</span></td>
                <td>Discovery Expired</td>
              </tr>}
              {discovery.status === 'claimed' &&<tr>
                <td>{new Date(discovery.claimDate).toLocaleDateString()} <span>{new Date(discovery.claimDate).toLocaleTimeString()}</span></td>
                <td>Discovery claimed by {discovery.claimUser}</td>
              </tr>}
              <tr>
                <td>{new Date(discovery.activationDate).toLocaleDateString()} <span>{new Date(discovery.activationDate).toLocaleTimeString()}</span></td>
                <td>Discovery made</td>
              </tr>
             </tbody>
           </table>
          </div></div>
          <div className="discovery-the-keepers fade-in">
          
        <svg height="50" width="50" id="canvas">
          <circle cx="25" cy="15" r="14" style={{stroke: '#fff', strokeWidth: 2}}/>
          <polygon points="0,0 50,0 25.0,43.3" style={{fill: 'transparent', stroke: '#fff', strokeWidth: 2}}></polygon>
          </svg>
        </div></>}
    </div>
  )
}

export default DiscoveryPage;