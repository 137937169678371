import axios from 'axios';
import React from 'react'
import { Link } from 'react-router-dom';
import InventoryWrapper from '../../components/InventoryWrapper';
import Countdown from '../../Countdown';
import EpochPage from '../EpochPage';
import './HomePage.scss';

interface IProps {

}

const unixEpochDate:number = 1655784000;
const fifthEpochDate:number = 1687320000;
const sixthEpochDate:number = 1695441600;
const seventhEpochDate:number = 1687320000;
const HomePage = (props:IProps) => {
    const [secondsLeft, setSecondsLeft] = React.useState(0);
    const [nextEpoch, setNextEpoch] = React.useState(0);
    const [epoch, _setEpoch] = React.useState(5);

    function setEpoch() {
      _setEpoch(epoch + 1);
    }

    function nothing() {
    }

    React.useEffect(() => {
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/getTimestamp`;
        
        axios({
          "method": "GET",
          "url" : url
        })
        .then((response) => {
          //console.log(response.data);
            

            if (fifthEpochDate <= response.data) {
              setEpoch();
            } 
           
              setSecondsLeft(fifthEpochDate - response.data);
              setNextEpoch(sixthEpochDate - response.data);
          
        })
        .catch((error) => {
          console.log(error)
        })
      }, [])

  return (
    <div className="home-page fade-in">
      <div className='menu-bar'>
        <ul><li><Link to={'/about'}>About</Link></li>
        <li><Link to={"/experiment"}>Experiment</Link></li>
        <li><a href={"https://t.me/the_gold_crow"}>Telegram</a></li>
        <li><a href={"https://discord.gg/xybcszGygW"}>Discord</a></li>
        </ul></div>
      <div className='epoch-counter'>
       <div className="animation">
        <span className="ring">
          <span className="ball" ></span>
        </span>
        <span className="ring2">
          <span className="ball"></span>
        </span>
        <span className="ring3">
          <span className="ball"></span>
        </span>
        <span className="ring4">
          <span className="ball"></span>
        </span>
        <span className="ring5">
          <span className="ball"></span>
        </span>
      </div>
      
      <div className="wrapper">
        <div className="header">
          
           <img src={"/the-gold-crow.png"}/>
          
        </div>

        
        <div className="figure-plate">
          <div className="figure-content">
            
          {epoch === 5 && secondsLeft > 0 && <h1><span className="chapter-title epoch2">The Seventh Epoch:</span> <span className="sub-title">Catch Fifteen Gold Crow<br /> &amp; <Link to="/experiment">The Ballad of the Brass Dragon</Link></span></h1>}
          {epoch === 6 && nextEpoch > 0 && <h1><span className="chapter-title epoch2">The Eighth Epoch:</span> <span className="sub-title">Catch Seventy-Five Gold Crow<br /> &amp; <Link to="/experiment">The Polarity of Sunny Days</Link></span></h1>}
            <div className="epoch-wrapper">
        {secondsLeft > 0 && epoch === 5 && <div className="fade-in"><Countdown setEpoch={setEpoch} secondsLeft={secondsLeft} /></div>}
        {epoch === 6  && nextEpoch > 0 && <div className="fade-in"><Countdown setEpoch={nothing} secondsLeft={nextEpoch} /></div>}
        </div>
          <div className="sockets">
            <div className="socket key1"></div>
            <div className="socket key2"></div>
            <div className="socket key3"></div>
          </div>
        </div>
        </div>
        <div className="the-keepers">
          <span>an invitation from the keepers</span>
          <a href="https://discord.gg/xybcszGygW">
        <svg height="50" width="50" id="canvas">
          <circle cx="25" cy="15" r="14" style={{stroke: '#fff', strokeWidth: 2}}/>
          <polygon points="0,0 50,0 25.0,43.3" style={{fill: 'transparent', stroke: '#fff', strokeWidth: 2}}></polygon>
          </svg></a>
        </div>
        </div>
        
      </div>
      <div className='epoch-summary-wrapper'>      
      <EpochPage />
      </div>

      <div className="contract">0xe10535928af57f0a043609b73a904f6416ec7317</div>
    </div>
  )
}

export default HomePage;