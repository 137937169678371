import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import { Link, NavLink } from 'react-router-dom';
import VConsole from 'vconsole';

import { AppContext } from '../../AppContext';
import ModalButtonBar from '../ModalButtonBar';
import ModalChoice from '../ModalChoice';
import ModalContainer from '../ModalContainer';
import ResourceBalance from '../ResourceBalance';
import UserManager from '../UserManager';

import './AudioControl.scss';
interface IProps {

}


declare var window: any;



const tracks = ['Ghosts-of-the-Past', 'The-Circle-is-Drawn', 'The-Gold-Crow-Slow'];
const volumeReducer = .5;
const userVolume = (getDefaultStateNumber('volume') || 0.15);
const initialVolume = userVolume * volumeReducer;

var audioCtx:AudioContext =  new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'playback' })
var droneNode = audioCtx.createGain();
var trackNode = audioCtx.createGain();
var musicPlaying = false;


const AudioControl = (props: IProps) => {





        


  const context = useContext(AppContext);
  
 
        //trackNode.gain.value = 0.0001; 
  
  //const soundEnabled = Boolean(context.user?.attributes.);
  const consoleEnabled = context.user?.attributes.admin || false;
  // const effectsVolume = context.effectsVolume || 0.5;
  // const effectsMuted = context.effectsMuted || true;

  
  //console.log(soundEnabled);
  const [intVolume, setIntVolume] = useState<number>(userVolume);
  //const [effectsVolume, setEffectsVolume] = useState<number>(context.effectsVolume || 0.5);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [trackPlaying, setTrackPlaying] = useState<boolean>(false);
  useEffect(() => {
    localStorage.setItem("volume", String(intVolume));
   setVolume(intVolume);
  }, [intVolume]);

  function onCloseHandler() {
    setShowControls(false);
  }


  const [userInteracted, _setUserInteracted] = useState<boolean>(false);
  const [isSoundPlaying, setIsSoundPlaying] = useState<boolean>(false);
  const [audioNode, setAudioNode] = useState<GainNode>();
  //const [effectsMuted, _setEffectsMuted] = useState<boolean>(getDefaultStateBoolean('effectsMuted'));
  const [muted, _setMuted] = useState<boolean>(getDefaultStateBoolean('muted'));
  const [lastTrack, setLastTrack] = useState<number>();
  const [volume, _setVolume] = useState<number>(initialVolume);
  const [audioSources, setAudioSources] = useState<AudioBufferSourceNode[]>([]);


  useEffect(() => {
    window.removeEventListener("touchStart", onUserInteraction);
    window.removeEventListener("click", onUserInteraction);
   if(userInteracted) {
     playSound();
   } 
 }, [userInteracted]);

 useEffect(() => {
   
   if(isSoundPlaying) {
    
     playSound();
   }
 }, [isSoundPlaying]);

 function setVolume(volume:number) {
  _setVolume(volume * volumeReducer);
 }

 function setMuted(muted:boolean) {
  localStorage.setItem("muted", String(muted));
   _setMuted(muted);
 }



 function setUserInteracted(interacted:boolean) {
  //localStorage.setItem("userInteracted", String(interacted));
   _setUserInteracted(interacted);
 }

 useEffect(() => {
   //console.log("muted " + muted);
  if (!muted) {
    droneNode.gain.setTargetAtTime(volume, audioCtx.currentTime, 0.15);
    trackNode.gain.setTargetAtTime(volume, audioCtx.currentTime, 0.15);
  } else {
    droneNode.gain.setTargetAtTime(0, audioCtx.currentTime, 0.15);
    trackNode.gain.setTargetAtTime(0, audioCtx.currentTime, 0.15);
  }

  //  if (volume === 0) {
  //    setMuted(false);
  //  } else {
  //    setMuted(true);
  //  }
 }, [volume, muted]);

 var playSound = function myPlaySound() {
   playSound = function(){}

       
            


    if(!muted) {
      trackNode.gain.exponentialRampToValueAtTime(volume, audioCtx.currentTime + 5);
      droneNode.gain.exponentialRampToValueAtTime(volume, audioCtx.currentTime + 5);
    }
    
      fetch("/audio/Drone-96.mp3").then(resp=>resp.arrayBuffer().then(function(data) {
        
        if(!musicPlaying) {
          musicPlaying = true;
          audioCtx.decodeAudioData(data, function(buffer:any) {
           
            let droneSource = audioCtx.createBufferSource();
            droneSource.buffer = buffer;
      droneSource.connect(droneNode);
      droneSource.loop = true;
            droneSource.start(0);
            
            playRandomTrack();
        });
        }
        
      })); 
        
    
    

        
 }


 function getRandomInt(max:number) {
   return Math.floor(Math.random() * max);
 }

 function playRandomTrack() {

     
   let trackIndex = getRandomInt(tracks.length);
   while (trackIndex == lastTrack) {
     trackIndex = getRandomInt(tracks.length);
   }
   setLastTrack(trackIndex);

   fetch(`/audio/${tracks[trackIndex]}.mp3`).then(resp=>resp.arrayBuffer().then(function(data) {
     //console.log("playing track:" + tracks[trackIndex]);
    audioCtx.decodeAudioData(data, function(buffer:any) {
     let trackSource = audioCtx.createBufferSource();



     trackSource.connect(trackNode);
        trackSource.buffer = buffer;
        trackSource.start(0);
        //trackNode.gain.setTargetAtTime(volume, audioCtx.currentTime, 0.15);
        setTrackPlaying(true);
        trackSource.onended = function() {
          setTrackPlaying(false);
          //console.log('onendedcalled');
         setTimeout(() => {
          
           playRandomTrack()
         }, 10000);
       }
    });
   }));
   
  
 }



function onUserInteraction() {
 setUserInteracted(true);
}


useEffect(() => {
 // console.log('called again');
 window.addEventListener("click", onUserInteraction);
 window.addEventListener("touchStart", onUserInteraction);
 audioCtx =  new (window.AudioContext || window.webkitAudioContext)({ latencyHint: 'playback' });
 droneNode = audioCtx.createGain();
 trackNode = audioCtx.createGain();
 
 droneNode.gain.value = 0.0001; 
 droneNode.connect(audioCtx.destination);
 
 
 trackNode.gain.value = 0.0001; 
 trackNode.connect(audioCtx.destination);
//console.log('remounted');

 return () => {
   window.removeEventListener("touchStart", onUserInteraction);
   window.removeEventListener("click", onUserInteraction);
  droneNode.disconnect();
  trackNode.disconnect();
  audioCtx.close();
  //console.log('unmounted');
 };
}, []);



function toggleSound() {
 setMuted(!muted);
 
}
function toggleEffects() {
  context.setEffectsMuted!(!context.effectsMuted);
 }

 function muteAll() {
    context.setEffectsMuted!(true);
    setMuted(true);
 }
 function unmuteAll() {
  context.setEffectsMuted!(false);
  setMuted(false);
 }


// useEffect(() => {
//  if(muted) {
//    droneNode.gain.setTargetAtTime(0, audioCtx.currentTime, 0.015);
//    trackNode.gain.setTargetAtTime(0, audioCtx.currentTime, 0.015);
//  } else {
//    droneNode.gain.setTargetAtTime(volume, audioCtx.currentTime, 0.015);
//    trackNode.gain.setTargetAtTime(volume, audioCtx.currentTime, 0.015);
//  }
// }, [muted]);

function getDefaultStateBoolean(key: string): boolean {
  let string = localStorage.getItem(key)
  return string ? Boolean(JSON.parse(string)) : false;
}
function getDefaultStateString(key: string): string {
  let string = localStorage.getItem(key)
  return string ? JSON.parse(string) : '';
}

function isAllMuted():boolean {
  if (context.effectsMuted && muted) {
    return true;
  } else {
    return false;
  }
}
 //if(consoleEnabled) {
  var vconsole = new VConsole({ theme: 'dark' });
 //}
//localStorage.setItem("summaryReverse", String(reverse));
// let effectsMuted = context.effectsMuted || true;
// let effectsVolume = context.effectsVolume || 0.5;

// console.log(effectsMuted + " " + effectsVolume);
// console.log(context.effectsMuted);


  return (
    
    <div className="audio-control">
       <button onClick={() => setShowControls(true)} >
     
                  {!muted && intVolume >= 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" />
                  </svg>}
                  {!muted && intVolume >= 0.33 && intVolume < 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5,9V15H9L14,20V4L9,9M18.5,12C18.5,10.23 17.5,8.71 16,7.97V16C17.5,15.29 18.5,13.76 18.5,12Z" />
                  </svg>}
                  {!muted && intVolume > 0 && intVolume < 0.33 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7,9V15H11L16,20V4L11,9H7Z" />
                  </svg>}
                  {(muted || intVolume === 0) && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z" />
                  </svg>}
                
      </button>

      {showControls && ReactDOM.createPortal(
        <ModalChoice onClose={onCloseHandler}>
          <ModalContainer className={'audio-settings-modal'}>

            <h2>Audio Settings</h2>
            <>
            {!isAllMuted() && <button className='mute-button' onClick={muteAll}>Mute All</button>}
            {isAllMuted() && <button className='mute-button' onClick={unmuteAll}>Unmute All</button>}
            <label>
                Effects Volume
                <div className='volume-control'>
                <div onClick={toggleEffects} className='volume-icon'>
                  {!context.effectsMuted && context.effectsVolume! >= 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" />
                  </svg>}
                  {!context.effectsMuted && context.effectsVolume! >= 0.33 && context.effectsVolume! < 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5,9V15H9L14,20V4L9,9M18.5,12C18.5,10.23 17.5,8.71 16,7.97V16C17.5,15.29 18.5,13.76 18.5,12Z" />
                  </svg>}
                  {!context.effectsMuted && context.effectsVolume! > 0 && context.effectsVolume! < 0.33 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7,9V15H11L16,20V4L11,9H7Z" />
                  </svg>}
                  {(context.effectsMuted || context.effectsVolume === 0) && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z" />
                  </svg>}
                </div>
                <div>
                  <input type="range" value={context.effectsVolume} min={0} max={1} step={.05} onChange={(e) => context.setEffectsVolume!(Number(e.target.value))} />
                </div>
                </div>
                
                </label>

              <label>
                Music Volume
                <div className='volume-control'>
                <div onClick={toggleSound} className='volume-icon'>
                  {!muted && intVolume >= 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" />
                  </svg>}
                  {!muted && intVolume >= 0.33 && intVolume < 0.66 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5,9V15H9L14,20V4L9,9M18.5,12C18.5,10.23 17.5,8.71 16,7.97V16C17.5,15.29 18.5,13.76 18.5,12Z" />
                  </svg>}
                  {!muted && intVolume > 0 && intVolume < 0.33 && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7,9V15H11L16,20V4L11,9H7Z" />
                  </svg>}
                  {(muted || intVolume === 0) && <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z" />
                  </svg>}
                </div>
                <div>
                  <input type="range" value={intVolume} min={0} max={1} step={.05} onChange={(e) => setIntVolume(Number(e.target.value))} />
                </div>
                </div>
                
                </label>

            </>

            <ModalButtonBar cancelLabel='Close' onCancel={onCloseHandler} />

          </ModalContainer>
        </ModalChoice>
        , document.body)
      }
    </div>
  )
}

function getDefaultStateNumber(key: string): number | undefined {
  let string = localStorage.getItem(key)
  return string ? Number(JSON.parse(string)) : undefined;
}

export default AudioControl;