import axios from 'axios';
import { connect } from 'http2';
import React from 'react'
import { useState, useEffect } from 'react';

import {Link, useParams, Outlet} from "react-router-dom";
import { AppContext } from '../../AppContext';
import Circle from '../../components/Circle';
import ExperimentNav from '../../components/ExperimentNav';
import FigureDetail from '../../components/FigureDetail';
import FigureDisplay from '../../components/FigureDisplay';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import GlobalVials from '../../components/Quest/GlobalVials';
import ResourceBalance from '../../components/ResourceBalance';
import Vial from '../../components/Vial';
import Balances from '../../interfaces/Balances';
import CostItem from '../../interfaces/CostItem';
import SupplyToken from '../../interfaces/SupplyToken';
import Task from '../../interfaces/Task';
import TransferConfirmation from '../../interfaces/TransferConfirmation';
import User from '../../interfaces/User';
import SupplyItem from './SupplyItem/SupplyItem';
import './SupplyPage.scss';

interface IProps {
  
}

const SupplyPage = (props:IProps) => {
  const size = useWindowSize();
    const context = React.useContext(AppContext);
    const session = context.userSession;
    const user = context?.user || {} as User;

    const tier4Active = user?.attributes?.tier || false;
    
    const scrollPosition = useScrollPosition() + (size.height);
    const [supply, setSupply] = React.useState<SupplyToken[][]>([]);
    const [supplyRevision, setSupplyRevision] = React.useState<number>(0);

    const [showAffordable, setShowAffordable] = React.useState<boolean>(false);

    const [filteredSupply, setFilteredSupply] = React.useState<SupplyToken[][]>([]);

    const [activeTier, setActiveTier] = React.useState<number>(1);
    const [isClaimPending, setIsClaimPending] = React.useState<boolean>(false);
    var eventSource: EventSource | undefined;

    React.useEffect(() => {

        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/supply`;
        context.setShowLoader?.('Loading The Supply', true);
        axios({
            "method": "GET",
            "url": url
          })
          .then((response) => {
            let newSupply:SupplyToken[][] = response.data;
            setSupply(newSupply);
            setFilteredSupply(filterSupply(newSupply));
            context.setShowLoader?.('Loading The Supply', false);
          })
          .catch((error) => {
            console.log(error);
            context.setShowLoader?.('Loading The Supply', false);
          })
    
      }, []);
    
      React.useEffect(() => {
    

        eventSource = new EventSource(`${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/events`);
        eventSource.addEventListener("SupplyUpdate", (e:any) => {
          setSupply(JSON.parse(e.data))
        });
        //eventSource.onmessage = e => updateTask(JSON.parse(e.data));
        eventSource.onopen = (e) => {console.log("connection opened")}
        //console.log(taskBalances);
        
      
      return () => {
        eventSource?.close();
        
      }
    
    
     
    }, [])

      function canAfford(item:CostItem):boolean {
        switch (item.elementId) {
          case 1:
            return item.amount <= (context.balances?.death || 0);
          case 2:
            return item.amount <= (context.balances?.life || 0);
          case 3:
            return item.amount <= (context.balances?.fire || 0);
          case 4:
            return item.amount <= (context.balances?.water || 0);
          case 5:
            return item.amount <= (context.balances?.earth || 0);
          case 6:
            return item.amount <= (context.balances?.air || 0);
          case 7:
            return item.amount <= (context.balances?.memory || 0);
          case 8:
            return item.amount <= (context.balances?.vision || 0);
          
          }
        return false;
      }

      function canAffordAll(items:CostItem[]):boolean {

        for (let i = 0; i < items.length; i++) {
          let canAffordItem = canAfford(items[i]);
          if(!canAffordItem) {
            return false;
          }
        }
        return true;
      }

function filterToken(token:SupplyToken):boolean {
  let showToken = true;
  if(showAffordable) {
    showToken = canAffordAll(token.costItems);
    //console.log(showToken);
  }
  //console.log('ran');
  return showToken;
}

function filterSupply(supply:SupplyToken[][]):SupplyToken[][] {
  let filteredSupply = [... supply];
  for(let i = 0; i < filteredSupply.length; i++) {
    filteredSupply[i] = filteredSupply[i].filter((token:SupplyToken) => filterToken(token));
    
  }
  return filteredSupply;
}

React.useEffect(() => {
  setFilteredSupply(filterSupply(supply));
}, [showAffordable]);

React.useEffect(() => {
  setFilteredSupply(filterSupply(supply));
}, [supply]);

function claimSupplyToken(token:number) {

  if (token && !isClaimPending) {
      let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/supply/exchange`;
      setIsClaimPending(true);
      context.setShowLoader?.('Exchanging for Figure', true);
      axios({
          "method": "POST",
          "url": url,
          "headers": {
              "signedMessage": session?.signature as string,
              "originalMessage": session?.message as string,
              "address": session?.address as string,
              'content-type': 'application/json'
          },
          "data": token

      })
          .then((response) => {
            let confirmation: TransferConfirmation = response.data;
                        context.setUserBalance?.(confirmation.balance);
                        setIsClaimPending(false);
                        //setFilteredSupply(filterSupply(supply));
           // let elementMasteryList:ElementMastery[] = response.data;
            //setElementMasteryList(elementMasteryList);
              context.setShowLoader?.('Exchanging for Figure', false);
          })
          .catch((error) => {
              console.log(error)
              setIsClaimPending(false);
              context.setShowLoader?.('Exchanging for Figure', false);
          })
  }



}

  return (
    <div className="supply-page fade-in">
      <div className='user-balance'>
      <ResourceBalance balances={context.balances} />
      </div>
      {filteredSupply && Boolean(filteredSupply.length > 0) && <div className='page-content-wrapper'>
      <PageWrapper>
      <PageHeader>
        The Supply
      </PageHeader>
      <PageNav children={undefined}></PageNav>
      <div className='supply-filters'>
        {context.auth && <div className='supply-filter'>
        <label className={showAffordable ? 'active' : ''}>
        <input onChange={(e) => setShowAffordable(!showAffordable)} type="checkbox" checked={showAffordable} />
          <span className='check-box'></span> Show Claimable
          </label>
        </div>}
      </div>
      <>
      <div className={'tier-nav '  + (tier4Active ? 'size-4' : 'size-3')}>
        <div className={'tier-nav-wrapper ' + (tier4Active ? 'size-4' : 'size-3')}>
        <div><button onClick={() => setActiveTier(1)}>Tier I</button></div>
        <div><button onClick={() => setActiveTier(2)}>Tier II</button></div>
        <div><button onClick={() => setActiveTier(3)}>Tier III</button></div>
        {tier4Active && <div><button onClick={() => setActiveTier(4)}>Tier IV</button></div>}
        </div>
        <span style={{transform : 'translateX(' + ((activeTier -1) * 100) +  '%'}}></span>
      </div>
        <div key={'tier' + activeTier} className='supply-tier'>
           {filteredSupply[activeTier - 1] && filteredSupply[activeTier - 1].length > 0 && <ul>
            {filteredSupply[activeTier - 1].map((token:SupplyToken) => 
                <li key={token.id}>
                  <SupplyItem filter={'filter-' + + String(showAffordable)} key={token.id} claimPending={isClaimPending} onClaim={claimSupplyToken} scrollPosition={scrollPosition} token={token} />
                </li>
            )}
             
            </ul>}
            {filteredSupply[activeTier - 1] && filteredSupply[activeTier - 1].length == 0 && 
              <div className='supply-empty fade-in'>
                
                <h2>There are no figures within your reach.</h2>
              </div>
             }
        </div>
      
      </>
      </PageWrapper></div> }
    </div>
  )
}

export default SupplyPage;


const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0 ,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (window && window.innerWidth && window.innerHeight) {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
      }
      
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}