import axios from 'axios';
import { totalmem } from 'os';
import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import Balances from '../../../interfaces/Balances';
import ElementTransaction from '../../../interfaces/ElementTransaction';
import RewardToken from '../../../interfaces/RewardToken';
import Task from '../../../interfaces/Task';
import TaskContributionElement from '../../../interfaces/TaskContributionElement';
import TransferConfirmation from '../../../interfaces/TransferConfirmation';
import UserTaskRank from '../../../interfaces/UserTaskRank';
import FigureDisplay from '../../FigureDisplay';
import ModalButtonBar from '../../ModalButtonBar';
import ModalChoice from '../../ModalChoice';
import ModalContainer from '../../ModalContainer';
import TotalProgress from '../../TotalProgress';


import './GlobalVials.scss';
import VerticalVial from './VerticalVial';
interface IProps {
    task: Task;
    onTaskUpdate(task: Task): void;
}

const GlobalVials = (props: IProps) => {

    const context = useContext(AppContext);
    const session = context.userSession;
    const balances = context.balances;
    const [selectedElement, setSelectedElement] = React.useState<number | undefined>(undefined);
    const [saving, setSaving] = React.useState<boolean>(false);
    const [transferAmount, setTransferAmount] = React.useState<string>('0');
    const [showUserRank, setShowUserRank] = React.useState<boolean>(context.auth || false);
    const [userTaskRewards, setUserTaskRewards] = React.useState<RewardToken[]>([]);
    const [rewardsLoaded, setRewardsLoaded] = React.useState<boolean>(false);
    //const [rankedList, setRankedList] = React.useState<UserTaskRank[]>(getRankList(true));
    //const [userRankNumber, setUserRankNumber] = React.useState<number>(getUserRankNumber());
    const style = { "--task-background-image": `url('https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/${props.task.backgroundImageKey}/taskbackground')` } as React.CSSProperties;

    function onClickHandler(elementId: number): void {
        if (context.auth) {
            setSelectedElement(elementId);
        }

    }

    function onCloseHandler() {
        setSelectedElement(undefined);
        setTransferAmount('0');
    }


    function onTransferAmountChanged(e: React.ChangeEvent<HTMLInputElement>): void {
        // console.log(e.target.value);
        setTransferAmount(e.target.value || '');
    }

    function onSliderChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setTransferAmount(e.target.value || '0');
    }

    function getElementBalance(elementId: number): number {
        if (elementId === 1) {
            if (Number.isNaN(balances?.death)) {
                return 0;
            }
            return balances?.death || 0;
        }
        if (elementId === 2) {
            if (Number.isNaN(balances?.life)) {
                return 0;
            }
            return balances?.life || 0;
        }
        if (elementId === 3) {
            if (Number.isNaN(balances?.fire)) {
                return 0;
            }
            return balances?.fire || 0;
        }
        if (elementId === 4) {
            if (Number.isNaN(balances?.water)) {
                return 0;
            }
            return balances?.water || 0;
        }
        if (elementId === 5) {
            if (Number.isNaN(balances?.earth)) {
                return 0;
            }
            return balances?.earth || 0;
        }
        if (elementId === 6) {
            if (Number.isNaN(balances?.air)) {
                return 0;
            }
            return balances?.air || 0;
        }
        if (elementId === 7) {
            if (Number.isNaN(balances?.memory)) {
                return 0;
            }
            return balances?.memory || 0;
        }
        if (elementId === 8) {
            if (Number.isNaN(balances?.vision)) {
                return 0;
            }
            return balances?.vision || 0;
        }
        return 0;
    }



    function transferElements() {
        if (context.auth) {


            let transaction: ElementTransaction = {} as ElementTransaction;
            transaction.type = 1;
            transaction.eventId = props.task.id;
            if (selectedElement === 1) {
                transaction.death = Number(transferAmount);
            } else if (selectedElement === 2) {
                transaction.life = Number(transferAmount);
            } else if (selectedElement === 3) {
                transaction.fire = Number(transferAmount);
            } else if (selectedElement === 4) {
                transaction.water = Number(transferAmount);
            } else if (selectedElement === 5) {
                transaction.earth = Number(transferAmount);
            } else if (selectedElement === 6) {
                transaction.air = Number(transferAmount);
            } else if (selectedElement === 7) {
                transaction.memory = Number(transferAmount);
            } else if (selectedElement === 8) {
                transaction.vision = Number(transferAmount);
            }



            if (selectedElement) {
                let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/transferElements`;
                setSaving(true);
                context.setShowLoader?.('Transferring Elements', true);
                axios({
                    "method": "POST",
                    "url": url,
                    "headers": {
                        "signedMessage": session?.signature as string,
                        "originalMessage": session?.message as string,
                        "address": session?.address as string,
                        'content-type': 'application/json'
                    },
                    "data": transaction

                })
                    .then((response) => {
                        setSaving(false);
                        let confirmation: TransferConfirmation = response.data;
                        context.setUserBalance?.(confirmation.balance);
                        //props.onTaskUpdate(confirmation.task);
                        //console.log(confirmation.transaction);
                        context.setShowLoader?.('Transferring Elements', false);
                        setTransferAmount('0');
                        setSelectedElement(undefined);
                    })
                    .catch((error) => {
                        console.log(error)
                        // setError("There was a problem taking " + selectedUsername.userName + ", it may have already been claimed, please select another name.")
                        // setSelectedUsername(undefined);
                        setSelectedElement(undefined);
                        setTransferAmount('0');
                        setSaving(false);
                        context.setShowLoader?.('Transferring Elements', false);
                    })
            }


        }
    }

    // function getUserRank(userId:number):JSX.Element {
    //     let userRank:UserTaskRank|undefined = props.task.ranks?.filter((userRank:UserTaskRank) => userRank.userId === userId)[0];
    //     if(userRank) {
    //         return <div className='user-ranking'><span className='user-name'>{userRank.userName}</span> <span className='user-rank'><span className='user-rank-number'>#</span>{userRank.totalRank}</span> <span className='user-additional'>({userRank.totalPercent.toFixed(2)}%)</span></div>;
    //     } else {
    //         return <div className='user-ranking'>Unranked</div>;
    //     }
    // }

    function getUserRankNumber(): number {
        let userId = session?.user?.id;
        if (userId) {
            let userRank: UserTaskRank | undefined = props.task.ranks?.filter((userRank: UserTaskRank) => userRank.userId === userId)[0];
            if (userRank) {
                return userRank.totalRank;


            } else {
                return 0;
            }
        } else {
            return 0;
        }

    }

    function getRankList(): UserTaskRank[] {
        let startPos = 0;

        if (showUserRank) {
            let foundUserRank = getUserRankNumber();
            //console.log(foundUserRank);
            if (foundUserRank > 3) {
                startPos = foundUserRank - 3;
            }
        }
        let endPos = startPos + 5;
        let list = props.task.ranks?.sort((a: UserTaskRank, b: UserTaskRank) => a.totalRank - b.totalRank).slice(startPos, endPos);
        if (list) {
            return list
        } else {
            return [];
        }
    }

    function getElementName(selectedElement: number) {
        if (selectedElement === 1) {
            return "Death";
        } else if (selectedElement === 2) {
            return "Life";
        } else if (selectedElement === 3) {
            return "Artifact (Fire)"
        } else if (selectedElement === 4) {
            return "Humor (Water)"
        } else if (selectedElement === 5) {
            return "Locus (Earth)"
        } else if (selectedElement === 6) {
            return "Craft (Air)"
        } else if (selectedElement === 7) {
            return "Memory (Past)"
        } else if (selectedElement === 8) {
            return "Vision (Future)"
        }
    }

    function transferAmountNumber(amount: string): number {
        if (Number.isNaN(amount)) {
            return 0;
        } else {
            return Number(amount);
        }
    }

    React.useEffect(() => {

        
         
    
            if (context?.userSession?.user?.id) {
              let userRewardsUrl = `${process.env.REACT_APP_SERVICE_BASEPATH}/tasks/${props.task.id}/rewards/user/${context?.userSession?.user.id}`;
              //context.setShowLoader?.('Loading Rewards', true);
              axios({
                "method": "GET",
                "url": userRewardsUrl
              })
                .then((response) => {
                  let rewardTokens:RewardToken[] = response.data;
                  setRewardsLoaded(true);
                  //let updatedPages = pages;
                  //updatedPages.push(rankPage);
                  setUserTaskRewards(rewardTokens);
                  //context.setShowLoader?.('Loading Rewards', false);
                })
                .catch((error) => {
                  console.log(error);
                  //context.setShowLoader?.('Loading Rewards', false);
                });
            }
            
        
    
    
    
      }, [props.task]);

    return (
        <div className={"global-vials fade-in " + (props.task.percentComplete >= 100 ? 'complete' : '')} style={style}>
            <div className='experiment-content'>
                <div className='task-description'>
                    <div className='task-detail'>{props.task.subtitle}</div>
                    <h1>{props.task.name}</h1>
                    <div className='task-menu'>
                        <ul>
                            <li><Link to={`${props.task.alias}/rankings`}>Rankings</Link></li>
                            <li><Link to={`${props.task.alias}/rewards`}>Rewards</Link></li>
                            <li><Link to={`${props.task.alias}/history`}>History</Link></li>
                        </ul>
                    </div>
                    {props.task.percentComplete < 100 && <TotalProgress percent={props.task.percentComplete} />}
                    {props.task.percentComplete >= 100 && <span className='complete-tag'>TASK COMPLETE {props.task.endDate}</span>}
                    <p>{props.task.description}</p>



                </div>
                <div className='global-vial-wrapper'>
                    {props.task.contribution.elements.map((element: TaskContributionElement) =>
                        <div key={'vial-' + element.id}>
                            <VerticalVial onClick={onClickHandler} total={element.max} amount={element.amount} element={element.id} />
                        </div>
                    )}

                </div>

                <div className='rank-list-wrapper'>
                {/* <div className='task-section-heading'>
                    <h2>Rankings</h2>
                    <div className='task-section-nav'>
                    <Link to={`${props.task.alias}/rankings`}>All Rankings</Link>
                    <Link to={`${props.task.alias}/history`}>History</Link>
                    </div>
                </div> */}
                    {props.task.ranks && <div className='task-ranking'>
                        {context.auth && (getUserRankNumber() > 0) && <div className='rank-toggle'><button onClick={() => setShowUserRank(true)}>Your Rank</button><button onClick={() => setShowUserRank(false)}>Top Ranked</button><span style={{ transform: (showUserRank ? 'translateX(0%)' : 'translateX(100%)') }}></span></div>}
                        {(!context.auth || (getUserRankNumber() === 0)) && <div className='rank-static'>Top Ranked<span></span></div>}
                        <div className='task-rank-table'>
                            <div className='rank-header'><span>Rank</span><span>Participant</span><span className='rank-header-total'>Total</span></div>
                            <ol>
                                {getRankList().map((userRank: UserTaskRank) =>
                                    <li key={userRank.totalRank}><div className={'rank-row ' + (userRank.userId === session?.user?.id ? 'current-user' : '')}><span className='rank-number'>{userRank.totalRank}</span> <span className='rank-user'>{userRank.userName}</span> <span className='rank-stat'>{userRank.totalPercent.toFixed(2)}%</span></div></li>
                                )}
                            </ol>
                        </div>
                    </div>}
                </div>

                
            </div>
            {rewardsLoaded && context.auth && <div className='user-task-rewards fade-in-fast'>
                <div className='task-section-heading'>
                    <h2>{props.task.percentComplete < 100 ? 'Your Prospective Rewards' : 'Your Rewards'}</h2>
                    <div className='task-section-nav'>
                    <Link to={`${props.task.alias}/rewards`}>All Rewards</Link>
                    </div>
                    <span></span>
                </div>
                    {Boolean(userTaskRewards.length > 0) && <ul>
                    {userTaskRewards.map((token:RewardToken) => 
                        <li key={token.id}><div className='reward-token-summary' key={token.id}><FigureDisplay token={token} size='quarter' /></div></li>
                    )}
                    </ul>}
                    {Boolean(userTaskRewards.length === 0) && <ul className='rewards-empty'>
                    <li>{props.task.percentComplete < 100 ? 'You Have No Prospective Rewards' : 'You did not win any rewards'} <Link to={`${props.task.alias}/rewards`}>View All Rewards</Link></li>
                    </ul>}
             </div>}
            
            {selectedElement && ReactDOM.createPortal(
                <ModalChoice onClose={onCloseHandler}>
                    <ModalContainer className={'approve-name-modal ' + (saving ? 'loading' : '')}>

                        <h2>{props.task.name}</h2>
                        <p>Sacrifice {getElementName(selectedElement)}</p>
                        <>
                            {getElementBalance(selectedElement) >= 1 && <>
                                <div className='percent-amount'>{Math.trunc((Number(transferAmount) / Math.trunc(getElementBalance(selectedElement))) * 100)}%</div>
                                <input type="range" value={transferAmount} min="0" max={Math.trunc(getElementBalance(selectedElement))} onChange={onSliderChange} step="1"></input>
                                <input type="hidden" value={transferAmount} onChange={onTransferAmountChanged} />

                                <div className='modal-warning'><p>You are about to sacrifice a percentage of your <strong>{getElementName(selectedElement)}</strong> to the task <strong>{props.task.name}</strong></p></div>
                            </>}
                        </>
                        <>
                            {getElementBalance(selectedElement) < 1 && <>
                                <div className='modal-warning'><p>You do not have any {getElementName(selectedElement)} to sacrifice...</p></div>
                            </>}
                        </>
                        <ModalButtonBar approveDisabled={Number(transferAmount) <= 0} onApprove={transferElements} onCancel={onCloseHandler} />

                    </ModalContainer>
                </ModalChoice>
                , document.body)
            }
        </div>
    )
}

export default GlobalVials;