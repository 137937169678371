import axios from 'axios';
import React from 'react'

import { AppContext } from '../../AppContext';
import MintableList from '../../components/MintableList';
import PageContent from '../../components/PageContent';
import PageHeader from '../../components/PageHeader';
import PageNav from '../../components/PageNav';
import PageWrapper from '../../components/PageWrapper';
import ResearchMasteryLevel from '../../interfaces/ResearchMasteryLevel';
import ResearchMastery from '../../interfaces/ElementMastery';
import User from '../../interfaces/User';
import './MasteryDetailPage.scss';
import ElementMastery from '../../interfaces/ElementMastery';
import ElementMasteryLevel from '../../interfaces/ElementMasteryLevel';
import MasteryBar from '../../components/MasteryBar';
import { NavLink, useParams } from 'react-router-dom';
import ResearchMasteryConfirmation from '../../interfaces/ResearchMasteryConfirmation';
import ResourceBalance from '../../components/ResourceBalance';

interface IProps {

}

const MasteryDetailPage = (props: IProps) => {

    const { elementAlias } = useParams();
    const context = React.useContext(AppContext);
    const session = context.userSession;
    const user = session?.user || {} as User;

    const [elementMasteryList, setElementMasteryList] = React.useState<ElementMastery[]>([]);

    React.useEffect(() => {

        getMasteryList();

    }, []);

    function getMasteryList() {
        let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery`;
        context.setShowLoader?.('Loading Research', true);
        axios({
            "method": "GET",
            "url": url,
            "headers": {
                "signedMessage": session?.signature as string,
                "originalMessage": session?.message as string,
                "address": session?.address as string
            }
        })
            .then((response) => {
                let elementMasteryList: ElementMastery[] = response.data;
                setElementMasteryList(elementMasteryList);
                context.setShowLoader?.('Loading Research', false);
                //context.setShowLoader?.('Loading Tasks', false);
            })
            .catch((error) => {
                console.log(error);
                context.setShowLoader?.('Loading Research', false);
                //context.setShowLoader?.('Loading Tasks', false);
            })
    }

    function researchNextLevel(masteryLevel:ElementMasteryLevel, count:number) {
        let research = {} as ResearchMasteryLevel;
        research.count = count;
        research.masteryLevelId = masteryLevel.id;
        if (masteryLevel && count > 0) {
            let url = `${process.env.REACT_APP_SERVICE_BASEPATH}/user/mastery/research`;
            //setSaving(true);
            context.setShowLoader?.('Beginning Research', true);
            axios({
                "method": "POST",
                "url": url,
                "headers": {
                    "signedMessage": session?.signature as string,
                    "originalMessage": session?.message as string,
                    "address": session?.address as string,
                    'content-type': 'application/json'
                },
                "data": research

            })
                .then((response) => {
                let confirmation:ResearchMasteryConfirmation = response.data;
                context.setUserBalance?.(confirmation.balance);
                setElementMasteryList(confirmation.masteryLevels);
                    context.setShowLoader?.('Beginning Research', false);
                })
                .catch((error) => {
                    console.log(error)

                    context.setShowLoader?.('Beginning Research', false);
                })
        }



    }

    function getRomanNumeral(number: number): string {
        switch (number) {
          case 1:
            return "I";
          case 2:
            return "II";
          case 3:
            return "III";
          case 4:
            return "IV";
          case 5:
            return "V";
          case 6:
            return "VI";
          case 7:
            return "VII";
          case 8:
            return "VIII";
          case 9:
            return "IX";
          case 10:
            return "X";
          default:
            return "-";
        }
      }

    function getElementName(elementId: number): string {
        switch (elementId) {
            case 1:
                return 'Death';
            case 2:
                return 'Life';
            case 3:
                return 'Artifact (Fire)';
            case 4:
                return 'Humor (Water)';
            case 5:
                return 'Locus (Earth)';
            case 6:
                return 'Craft (Air)';
            case 7:
                return 'Memory (Past)';
            case 8:
                return 'Vision (Future)';

        }
        return 'Death';
    }

    function getElementId(elementAlias: string): number {
        switch (elementAlias) {
            case 'death':
                return 1;
            case 'life':
                return 2;
            case 'artifact':
                return 3;
            case 'humor':
                return 4;
            case 'locus':
                return 5;
            case 'craft':
                return 6;
            case 'memory':
                return 7;
            case 'vision':
                return 8;

        }
        return 1;
    }

    const elementId = getElementId(elementAlias || '');
    return (
        <div className="mastery-page fade-in">
            <div className='user-balance'>
      <ResourceBalance balances={context.balances} />
      </div>
            <PageWrapper>
                <PageHeader>{getElementName(elementId) + " Research"}</PageHeader>
                <PageNav>
                <ul>
                  <li><NavLink to={`../research`} end>Research</NavLink></li>
                </ul>
                </PageNav>
                <PageContent>
                    <div>
                        {elementMasteryList.filter((elementMastery: ElementMastery) => elementMastery.elementId === getElementId(elementAlias || '')).map((elementMastery: ElementMastery) =>
                            <div className='mastery-section'>
                                {!Boolean(elementMastery.nextLevel?.userMasteryExp.length > 0) && !elementMastery.blocked && <div className='warning'>
                                    <h2>If you choose the path of {getElementName(elementMastery.elementId)} Mastery, it will block you from researching {getElementName(elementMastery.blocksElementId)} Mastery until the next&nbsp;Epoch!</h2>
                                    <p>Once you start down this path there is no turning back, so choose wisely...</p>
                                </div>}
                                {elementMastery.blocked && <div className='warning'>
                                    <h2>The path of {getElementName(elementMastery.elementId)} Mastery has been&nbsp;blocked!</h2>
                                    <p>You must wait until next Epoch to conduct this research...</p>
                                </div>}
                                {elementMastery.isMaxLevel && <div className='warning'>
                                    <h2>This is as far as you go</h2>
                                    <p>You have reached the maximum of 10 Mastery Levels...</p>
                                </div>}
                                <MasteryBar maxLevel={elementMastery.isMaxLevel} currentLevel={elementMastery.currentLevel} onResearchComplete={getMasteryList} active={elementMastery.active} blocked={elementMastery.blocked} onResearchNextLevel={researchNextLevel} elementId={elementMastery.elementId} masteryLevel={elementMastery.nextLevel} />
                                <div className='mastery-details fade-in'>
                                <h2>{getElementName(elementId) + " Mastery Overview"}</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th className='align-left'>Title</th>
                                            <th>Modifier</th>
                                            <th>Cost</th>
                                            <th>Req</th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                        {elementMastery.masteryLevels.map((level:ElementMasteryLevel) => 
                                            <tr className={level.level === elementMastery.currentLevel?.level ? 'hilight' : '' }>
                                                <td>{getRomanNumeral(level.level)}</td>
                                                <td className='align-left'>{level.title}</td>
                                                <td><span className='mastery-modifier'>+{level.modifier}%</span></td>
                                                <td><span className={'element-type element-' + elementMastery.elementId}></span>{'\u00A0'}{level.expCost}</td>
                                                <td>{level.expRequirement}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        )}
                    </div>
                </PageContent>
            </PageWrapper>
        </div>
    )
}

export default MasteryDetailPage;