import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import TaskHistory from '../../interfaces/TaskHistory';
import UserTaskRank from '../../interfaces/UserTaskRank';

import './FigureSlot.scss';
interface IProps {
    elementId:number;
}

const FigureSlot = (props: IProps) => {

    
    return (
        <div className={'figure-slot-wrapper glow-' + props.elementId}>
        <div className={'figure-slot open'}>
            
        </div>
            {props.elementId == 1 && <img src='/ui/symbol_death.png' />}
            {props.elementId == 2 && <img src='/ui/symbol_life.png' />}
            {props.elementId == 3 && <img src='/ui/symbol_fire.png' />}
            {props.elementId == 4 && <img src='/ui/symbol_water.png' />}
            {props.elementId == 5 && <img src='/ui/symbol_earth.png' />}
            {props.elementId == 6 && <img src='/ui/symbol_air.png' />}
            {props.elementId == 7 && <img src='/ui/symbol_memory.png' />}
            {props.elementId == 8 && <img src='/ui/symbol_vision.png' />}
        </div>
    )
}

export default FigureSlot;
